@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

/** Full Width DataTable Implementation from official the website
https://react-table-v7.tanstack.com/docs/examples/full-width-table */

.tableWrapper {
  display: block;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-left: (-$global-padding);
  width: calc(100% + #{$padding-reducer});
  margin-bottom: 20px;

  &--grid {
    overflow-x: hidden;
  }

  &.blankPage {
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    table {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .blank-slate {
        flex-grow: 1;
        margin-block: 20px;

        .card-body {
          justify-content: center;
        }
      }
    }
  }

  .action-cell {
    display: flex;
    justify-content: flex-end;
    gap: 12px;

    .btn-icon {
      min-width: 43px;
    }
  }
}

table.dataTable {

  /* Make sure the inner table is always as wide as needed */
  color: $gray-700;
  width: 100%;
  border-spacing: 0;

  &:not(&.dataTable--grid) {
    th,
    td {
      text-align: left;
      margin: 0;
      padding: 0.5rem;

      /* Each cell should grow equally */
      width: 1%;

      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0;
      }

      &.actions {
        text-align: right;

        .btn {
          height: 32px;
        }
      }
    }
  }

  a:not([type='button']) {
    color: $blue;
  }
}

.isListPage {
  .pagination {
    margin-top: auto;
  }
}
