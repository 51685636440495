@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

tbody.dataTableBody {
  td {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  td.actions {
    text-align: right;
  }

  tr {
    align-items: center;
    padding-right: 24px;
    padding-left: 24px;
    min-height: 48px;

    &[tag='warning'] {
      background-color: $yellow-50;
    }

    &[tag='error'] {
      background-color: $red-50;
    }
  }

  tr:nth-child(odd) {
    background-color: $white;

    &[tag='warning'] {
      background-color: $yellow-50;
    }

    &[tag='error'] {
      background-color: $red-50;
    }
  }

  &--grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 30px;
    padding: 0 30px;
    @media screen and (max-width: $bp-xl) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media screen and (max-width: $bp-lg) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: $bp-md) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: $bp-sm) {
      grid-template-columns: repeat(2, 1fr);
    }

    tr {
      padding: 0;
    }

    &.grid-4 {
      @media screen and (min-width: $bp-xl) {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    &.grid-5 {
      @media screen and (min-width: $bp-xl) {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }
}
