@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.answer-data {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  row-gap: 4px;

  &.label {
    background-color: $white;
    border-radius: 4px;
    max-width: 180px;
    padding: 4px 12px;
  }

  span {
    color: $gray-400;
  }

  span.dot {
    background-color: $red;
    border-radius: 50%;
    flex-shrink: 0;
    height: 12px;
    max-width: 100%;
    width: 12px;
  }

  .label-top {
    b {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 140px;
      display: inline-block;
    }
  }

  .label-top,
  .label-bottom {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
  }
}
