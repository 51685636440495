@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.activity {
  @include gutter-sizing('margin-bottom');
  display: flex;

  .thumbnail {
    box-shadow: inset 0 -1px 3px rgba(0, 0, 0, 0.3);
    height: 36px;
    margin-right: 10px;
    margin-top: 1px;
    width: 36px;
  }

  .action {
    color: $text-color-medium;
    display: block;
  }

  .name,
  .timestamp {
    display: inline;
    font-size: 1em;
  }

  .name {
    font-weight: $font-weight-bold;

    &:after {
      content: ' • ';
    }
  }

  .more-info {
    color: $text-color-medium;
    margin-top: 0.5em;
  }
}
