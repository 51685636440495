@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

thead.dataTableHeader {
  border-bottom: 1px solid $blue-100;
  font-weight: $font-weight-semibold;
  user-select: none;

  tr {
    padding-right: 24px;
    padding-left: 24px;
  }

  th {
    padding-right: 0;
    position: relative;

    &.sorted {
      color: $black;
    }

    &.actions {
      text-align: right;
      padding-right: 10px;
    }

    span.arrow {
      margin-left: 5px;
    }
  }

  .sort-icons {
    color: $gray-400;
  }
}
