@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.content-container {
  background-color: $background-gray-light-1;
  border-radius: $border-radius-lg $border-radius-lg 0 0;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: $container-max-width;
  min-height: calc(100vh - #{$topbar-height});
  overflow: hidden;
  position: relative;

  &.analytics-wrapper {
    .tabs {
      .loading-container.mask-container {
        height: inherit;
        top: 52px;
        background: transparent;
      }
    }
  }

  @media screen and (min-width: $bp-md) {
    border-radius: $border-radius-xl;
    min-height: $min-page-height;
  }

  .page-header {
    color: $text-color;

    .page-title {
      font-weight: $font-weight-semibold;
      margin-bottom: 0;
      margin-left: -0.025em;
    }

    .header-contents {
      display: flex;
      flex-wrap: wrap;
      padding: 16px $grid-gutter-width 0;

      @media screen and (min-width: $bp-md) {
        padding: 20px $grid-gutter-width-md 0;
      }

      @media screen and (min-width: $bp-xl) {
        padding: 24px $grid-gutter-width-xl 0;
      }

      .breadcrumbs {
        width: 100%;
      }

      .top-right {
        margin-left: auto;
        align-self: center;
        justify-self: flex-end;
      }
    }

    &.has-tabs {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.07) 100%);
    }

    &.highlight {
      background: $header-highlight-background;
      color: color-yiq($brand-color);

      .header-contents {
        padding-bottom: 16px;

        @media screen and (min-width: $bp-md) {
          padding-bottom: 20px;
        }

        @media screen and (min-width: $bp-xl) {
          padding-bottom: 24px;
        }
      }

      .badge {
        background: $white;
        color: $brand-color;
      }

      .nav-link.active { // For the current tab
        color: $text-color;
      }
    }

    &.has-breadcrumbs {
      .header-contents {
        padding-top: 8px;

        @media screen and (min-width: $bp-md) {
          padding-top: 12px;
        }

        @media screen and (min-width: $bp-xl) {
          padding-top: 16px;
        }
      }
    }

    &.has-breadcrumbs.highlight .header-contents,
    &.has-tabs .header-contents {
      padding-bottom: 8px;

      @media screen and (min-width: $bp-md) {
        padding-bottom: 12px;
      }

      @media screen and (min-width: $bp-xl) {
        padding-bottom: 16px;
      }
    }
  }

  .page-contents {
    flex-grow: 1;
    padding: 12px $grid-gutter-width;
    position: relative;

    &.isListPage {
      display: flex;
      flex-direction: column;

      .blank-page-notice {
        padding-top: 0;
      }
    }

    .loading-container.mask-container {
      background-color: inherit;
    }

    @media screen and (min-width: $bp-md) {
      padding: 18px $grid-gutter-width-md;
    }

    @media screen and (min-width: $bp-xl) {
      padding: 24px $grid-gutter-width-xl;
    }

    .page-contents-inner {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }
}

.nav.page-tabs {
  border-bottom: 0;
  flex-wrap: nowrap;
  overflow-y: hidden;

  .nav-item {
    margin: 0 ($grid-gutter-width-md + 16px) 0 $grid-gutter-width;

    @media screen and (min-width: $bp-md) {
      margin: 0 ($grid-gutter-width-md +20px) 0 $grid-gutter-width-md;
    }

    @media screen and (min-width: $bp-xl) {
      margin: 0 ($grid-gutter-width-xl + 28px) 0 $grid-gutter-width-xl;
    }
  }

  .nav-link {
    align-items: center;
    border-radius: 0;
    border: 0;
    color: inherit;
    display: flex;
    font-weight: $font-weight-semibold;
    height: 32px;
    margin: 0;
    padding: 4px 0;
    position: relative;

    @media screen and (min-width: $bp-md) {
      height: 40px;
      padding: 6px 0;
    }

    .tab-name {
      position: relative;
      z-index: 2;
    }

    &.active {
      background-color: transparent;
      color: $text-color;
    }

    &:hover .tab-background {
      opacity: 0.2;
      transform: scaleY(1);
    }

    &.active .tab-background {
      opacity: 1;
      transform: scaleY(1);
      z-index: 1;
    }

    .tab-background {
      background-color: $background-gray-light-1;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transform: scaleY(0.8);
      transform-origin: center bottom;
      transition: opacity 0.3s linear, transform 0.2s ease-in-out;
      width: 100%;

      &:before,
      &:after {
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        display: block;
        height: 33px;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 60px;

        @media screen and (min-width: $bp-md) {
          height: 41px;
          width: 75px;
        }
      }

      &:before {
        background-image: url('./img/page-tab-left.svg?inline');
        left: -59px;

        @media screen and (min-width: $bp-md) {
          left: -74px;
        }
      }

      &:after {
        background-image: url('./img/page-tab-right.svg?inline');
        right: -59px;

        @media screen and (min-width: $bp-md) {
          right: -74px;
        }
      }
    }
  }
}
