@if $unstyled-button-helper {
  .unstyled-button {
    appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    display: inline-block;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: inherit;
  }
}
