@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.breadcrumbs {
  .breadcrumb {
    margin-bottom: 0;
    margin-left: -0.05em;
  }

  .breadcrumb-item {
    color: inherit;
    font-weight: $font-weight-light;

    a,
    a:hover,
    a:focus,
    a:active {
      color: $link-color;

      .highlight & {
        color: color-yiq($brand-color);
      }
    }

    a:hover,
    a:focus,
    a:active {
      text-decoration: underline;
    }

    &:after {
      content: ' / ';
      display: inline-block;
      opacity: 0.5;
      padding-left: 0.25rem;
    }
  }
}
