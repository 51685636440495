@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.modal {
  text-align: center;

  .modal-dialog {
    align-items: center;
    display: flex;
    min-height: calc(100% - #{$spacer * 4});
    text-align: left;
    word-break: break-word;

    @each $color, $value in $theme-colors {
      &.#{$color} .modal-content {
        border-left: 6px solid $value;
      }
    }

    &.modal-auto {
      display: inline-block;
    }
  }

  .modal-header,
  .modal-body,
  .modal-footer {
    @include gutter-sizing([ 'padding-top', 'padding-bottom' ], 0.75);
    @include gutter-sizing([ 'padding-left', 'padding-right' ]);
  }

  .btn.close-btn {
    box-shadow: -4px 4px 12px 0 rgba(0, 0, 0, 0.05);
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(45%, -45%);
    z-index: 2;

    @media screen and (min-width: $bp-md) {
      font-size: rem(22);
      height: 48px;
      width: 48px;
    }
  }

  .modal-content {
    @media screen and (min-width: $bp-md) {
      border-radius: $border-radius-xl;
    }
  }

  .modal-body {
    overflow: hidden;
  }

  .close-btn .modal-body {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  .modal-body:last-child {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .modal-dialog.no-background {
    .modal-content {
      background-color: transparent;
      border: 0;
      box-shadow: none;
    }

    .modal-header,
    .modal-body,
    .modal-footer {
      padding: 0;
    }
  }

  // Workaround to fix overflowing React Select component in modal
  .modal-body .rs__input-container {
    max-width: 1px;
  }
}
