@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.panel {
  @include gutter-sizing('margin-bottom');

  .card-header {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &.gradient {
      color: rgba($white, 0.9);
    }

    .stat {
      height: 31px;

      .name {
        font-size: 14px;
        margin-right: 4px;
      }

      .number,
      .percentage {
        font-size: 24px;
        line-height: calc(32 / 24);
      }

      .number {
        font-weight: $font-weight-bold;
      }

      .percentage {
        font-weight: $font-weight-light;

        &:before {
          content: ' • ';
        }
      }
    }

    .card-title {
      font-size: 14px;
      letter-spacing: 0.18em;
      line-height: calc(20 / 14);
      position: relative;
      text-transform: uppercase;

      &.bg-solid {
        .popover-btn {
          svg path {
            fill: rgba($white, 0.8);
          }
        }
      }
    }

    &.gradient .card-title {
      font-weight: $font-weight-bold;
    }

    .card-icon {
      margin: 0 8px 0 -4px;
      font-size: 18px;

      .svg-inline--fa {
        margin-bottom: -1px;
      }
    }
  }

  &.chart-container .card-body {
    @include gutter-sizing(['padding-bottom'], 0.4);
  }
}
