@use 'sass:math';

@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.overall-analytics {
  .subheading {
    color: $text-color-medium;
    margin-bottom: 24px;

    .inline-date-field {
      display: inline-block;
    }
  }

  .panel .tab-pane {
    padding: 20px 20px 12px;
  }

  .tabs .ReactTable {
    margin: 0;
  }

  .tabs.panel {
    overflow: initial;

    .nav-tabs {
      border-bottom: 1px solid $card-border-color;
      padding: 12px 12px 0;

      .nav-item {
        padding-top: 10px;
      }

      @media screen and (min-width: $bp-md) {
        padding: 0 20px;
      }

      li .nav-link {
        background-color: transparent;
        border-bottom: 4px solid transparent;
        border-top: 0;
        color: $black-tertiary;
        font-size: 20px;
        line-height: (26 * 0.05);
        margin: 0;
        white-space: nowrap;

        @include gutter-sizing('margin-right');
        padding: 0 0 4px;
        position: relative;

        @media screen and (min-width: $bp-md) {
          font-size: $h3-font-size;
          line-height: math.div(30, 24);
          padding: 0 0 5px;
        }

        &:focus,
        &:hover {
          border-bottom: 4px solid $text-color;
          border-radius: 0;
        }

        &.active,
        &.active:focus,
        &.active:hover {
          border-bottom: 4px solid $text-color;
          border-radius: 0;
          color: $text-color;
        }
      }
    }

    .tab-pane {
      overflow-x: initial;
    }
  }

  .card.panel {
    .card-body {
      .numbers {
        display: flex;
        align-items: center;
        gap: 12px;

        svg {
          height: 30px;
          width: auto;
        }

        $colors: 'blue' $blue, 'green' $green, 'pink' $pink, 'red' $red, 'red-lighter' $red-lighter, 'orange' $orange;
        @each $name, $color in $colors {
          &-#{$name} svg {
            color: $color;
          }
        }
      }
    }
  }

  .analyticsTopInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    * {
      margin-bottom: 0;
    }
  }
}
