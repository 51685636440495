@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.checkbox-dropdown-container {
  display: flex;
  max-width: 100%;
}

.checkbox-dropdown {
  max-width: 100%;

  &.show .dropdown-trigger {
    border-bottom-left-radius: 0.1px;
    border-bottom-right-radius: 0.1px;
  }

  .dropdown-trigger {
    border-color: $input-border-color;
    min-width: $dropdown-min-width;
    max-width: 100%;
    position: relative;
    text-align: left;
    width: auto;
    padding-right: calc(#{$input-padding-x} + 32px);

    .trigger-title { // Scrollbar only disabled because scrolling isn't essential here, do not replicate elsewhere.
      margin: 0 #{0 - $input-padding-x};
      overflow-x: auto;
      overflow-y: hidden;
      padding: 0 12px;
      scrollbar-width: none;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .chevron-container {
      align-items: center;
      border-left: 1px solid $input-border-color;
      display: inline-flex;
      height: 100%;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 32px;
    }
  }

  .dropdown-menu {
    border-color: $input-border-color;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100%;
  }

  .custom-checkbox {
    align-items: center;
    background-color: $white;
    border: 1px solid $input-border-color;
    border-radius: $border-radius;
    color: $white;
    display: inline-flex;
    font-size: 10px;
    height: 16px;
    justify-content: center;
    margin-right: 8px;
    width: 16px;

    &.checked {
      background-color: $info;
      border-color: $info;
    }
  }
}
