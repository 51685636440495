@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.tabs {
  overflow: visible;

  .nav-tabs {
    flex-wrap: nowrap;

    &.sticky-nav {
      background-color: $background-gray-light-1;
      box-shadow: 0 1px 30px $background-gray-light-1;
      position: sticky;
      top: 59px;
      z-index: 1;
    }

    li {
      .nav-link {
        background-color: transparent;
        color: $text-color;
        cursor: pointer;
        margin: 0 4px;
        transition: background-color 0.3s, border-color 0.3s;

        &:hover,
        &:focus,
        &:active {
          background-color: rgba($text-color, 0.05);
          border-color: $border-color-light;
          outline: none;
        }

        &.active,
        &.active:focus,
        &.active:hover {
          background-color: transparent;
          border-color: $border-color-light;
          border-bottom-color: $background-gray-light-1;
        }
      }

      &.disabled .nav-link {
        color: $color-additional;
      }
    }

    .top-right-content {
      align-self: center;
      margin-right: 0;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .tab-pane {
    padding-top: 16px;
    overflow-x: hidden;
    overflow: visible;
  }

  &.bordered-top .nav-tabs li .nav-link {
    border-top: 2px solid transparent;
    border-radius: 0;

    &.active,
    &:focus,
    &:hover {
      border-top: 2px solid $green;
      overflow: visible;
    }
  }

  &.bordered-bottom .nav-tabs li .nav-link {
    border-color: transparent;
    border-left: 0;
    border-right: 0;
    border-bottom: 4px solid transparent;
    border-radius: 0;
    margin: 0;

    &.active {
      &,
      &:focus,
      &:hover {
        border-radius: 0;
        border-color: transparent;
        border-bottom: 4px solid $green;
      }
    }
  }

  &.fieldset {
    .nav-tabs {
      border-color: $border-color;

      li .nav-link {
        background-color: transparent;
        border-bottom: 4px solid transparent;
        border-top: 0;
        color: $text-color-muted;
        font-size: 20px;
        line-height: calc(26 / 20);
        margin: 0;

        @include gutter-sizing('margin-right');
        padding: 0 0 4px;
        position: relative;

        @media screen and (min-width: $bp-md) {
          font-size: $h3-font-size;
          line-height: calc(30 / 24);
          padding: 0 0 5px;
        }

        &:focus,
        &:hover {
          border-bottom: 4px solid $text-color;
          border-radius: 0;
        }

        &.active,
        &.active:focus,
        &.active:hover {
          border-bottom: 4px solid $text-color;
          border-radius: 0;
          color: $text-color;
        }
      }
    }
  }

  &.buttons {
    overflow: visible;

    .nav-tabs li {
      &:first-child .nav-link {
        border-top-left-radius: $border-radius;
      }

      &:last-child .nav-link {
        border-top-right-radius: $border-radius;
      }

      .nav-link {
        @include button-outline-variant($cyan, $white, $cyan);
        border-radius: 0;
        margin: 0;
        width: 100%;

        &.active {
          @include button-variant($cyan, $cyan, $cyan);
        }
      }
    }

    .tab-content {
      @include gutter-sizing(['padding'], 0.5);
      background: $white;
      border: 1px solid $border-color;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      border-top: 0;

      .tab-pane {
        padding: 0;

        > pre {
          @include gutter-sizing(['margin'], -0.5);
          padding: 12px 20px;
        }
      }
    }
  }

  .tab-buttons {
    @include gutter-sizing([ 'padding-top', 'padding-bottom' ]);
    border-top: 1px solid $border-color-light;
    display: flex;
    justify-content: space-between;
    overflow: visible;
  }

  .tabs-nav-wrap {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 30px;

    * {
      margin-bottom: 0;
    }

    .nav {
      flex: 1 0 auto;
    }
  }
}
