@if $aspect-ratio-helper {
  .container-aspect-ratio {
    height: 0;
    position: relative;
  }

  .container-16x9 {
    padding-top: calc(100% * 9 / 16);
  }

  .container-9x16 {
    padding-top: calc(100% * 16 / 9);
  }

  .container-1x1 {
    padding-top: 100%;
  }

  .container-4x3 {
    padding-top: calc(100% * 3 / 4);
  }

  .container-3x4 {
    padding-top: calc(100% * 4 / 3);
  }
}
