@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.error-message-page {
  @include gutter-sizing([ 'padding' ], 0.5);
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: $min-page-height;

  .alert {
    align-items: center;
    flex-direction: column;
  }

  .alert-icon {
    margin: 0 auto;
  }

  .alert-content {
    margin: 16px auto;
    text-align: center;
  }

  .btn-retry {
    margin-bottom: 12px;
    opacity: 0.9;
  }

  a.go-home {
    color: $text-color;
    text-decoration: underline;
  }
}
