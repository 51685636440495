@use 'sass:math';
@use 'sass:list';

@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.device-wrapper-container {
  margin: 0 auto;
  max-width: 417px;
  aspect-ratio: 9 / 16;
  height: auto;

  @media screen and (max-width: $bp-md) {
    height: 100%;
  }

  .device-wrapper {
    margin: auto;
    position: relative;
    width: 100%;
    height: 100%;

    &:after {
      filter: drop-shadow(0 8px 16px rgba(0, 0, 0, 0.15)) drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
    }

    @media screen and (min-width: $bp-md) and (min-height: 480px) {
      padding-bottom: (math.div(846, 417) * 100%);

      &:after {
        background-color: #111;
        border-radius: list.slash(math.div(40, 375) * 100%, math.div(40, 812) * 100%);
        bottom: 8px;
        content: '';
        display: block;
        left: 8px;
        position: absolute;
        right: 8px;
        top: 8px;
        z-index: 0;
      }
    }

    .spinner-container {
      position: absolute;
      top: 42%;
      z-index: 3;
    }

    .frame {
      display: none;

      @media screen and (min-width: $bp-md) and (min-height: 480px) {
        display: block;
        pointer-events: none;
        position: absolute;
        z-index: 2;
      }
    }

    .preview-container {
      border-radius: list.slash(math.div(8, 375) * 100%, math.div(8, 667) * 100%);
      height: 100%;
      margin: auto;
      overflow: hidden;
      width: 100%;

      @media screen and (min-width: $bp-md) and (min-height: 480px) {
        background-color: black;
        border-radius: list.slash(math.div(40, 375) * 100%, math.div(40, 812) * 100%);
        height: (math.div(812, 846) * 100%);
        left: 50%;
        position: absolute;
        top: (math.div(16, 846) * 100%);
        transform: translateX(-50%);
        width: (math.div(375, 417) * 100%);
        z-index: 1;

        &.preview-container--with-safe-area {
          padding-top: (math.div(44, 417) * 100%); // Percentage of container element
        }
      }
    }

    .modal & {
      margin: auto;
      max-width: (math.div(375, 768) * 100vh);
    }
  }
}

.story-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  position: relative;
  width: 100%;
  z-index: 4;

  @media screen and (min-width: $bp-md) and (min-height: 480px) {
    bottom: 0;
  }

  .btn {
    margin: 0 0.5em;
  }
}

.refresh-note {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
  vertical-align: middle;

  .btn-refresh {
    margin-right: 0.3em;
  }
}
