.light-blue-info {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 12px 20px;
  background-color: $blue-50;
  color: $black-secondary;
  border: 1px solid $blue-100;

  a {
    text-decoration: underline;
    color: inherit;
  }
}
