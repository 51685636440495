@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.progress-bar-container {
  font-size: rem(11);
  margin-bottom: 0.75em;
  position: relative;

  &.xs {
    .percentage {
      display: none;
    }

    .progress {
      height: rem(4);
    }
  }

  &.sm {
    font-size: rem(8);
  }

  &.lg {
    font-size: rem(16);
  }

  .title {
    font-size: 1.2em;
    line-height: $line-height-base;
    margin-bottom: 0.25em;
  }

  .percentage {
    font-size: 1.2em;
  }

  .progress-bar {
    font-weight: $font-weight-bold;
    text-align: left;
    text-indent: 0.5em;
    transition: width 0.2s ease;
    will-change: width;
  }

  .value-container {
    position: relative;
  }

  &.percentage-outside {
    padding-top: $line-height-base * 1em;

    .percentage {
      position: absolute;
      right: 0;
      margin-top: -1.75em;
    }

    &.has-title {
      padding-top: 0;
    }
  }

  &.uploading-bar .progress {
    background: $blue-100;

    .progress-bar {
      background: $blue;
      opacity: 1;
    }
  }
}
