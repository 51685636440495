@keyframes dragStart {
  0% {
    box-shadow: 0 0 0 rgba($black, 0);
    transform: scale(1);
  }

  100% {
    box-shadow: 0 2px 12px rgba($black, 0.1), 0 0 40px rgba($black, 0.1), $box-shadow-lg;
    transform: scale(1.1);
  }
}
