// Pass properties to this mixin to get the grid gutter value at each breakpoint
// eg. @include gutter-sizing('margin-bottom');
// eg. @include gutter-sizing([ 'padding-left', 'padding-right' ]);
// eg. @include gutter-sizing([ 'padding-left', 'padding-right' ], 2); // Double gutter
// eg. @include gutter-sizing([ 'padding-left', 'padding-right' ], 0.5); // Half gutter
@mixin gutter-sizing($properties, $multiplier: 1) {
  @each $breakpoint, $value in $grid-gutters {
    @if $breakpoint == 0 {
      @each $property in $properties {
        #{$property}: $value * $multiplier;
      }
    }
    @else {
      @media screen and (min-width: $breakpoint) {
        @each $property in $properties {
          #{$property}: $value * $multiplier;
        }
      }
    }
  }
}

// Box-shadow style for focused elements
@mixin focus-shadow($color) {
  box-shadow: 0 0 0 0.2rem rgba($color, 0.5);
}
