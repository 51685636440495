@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

// Fix tooltip arrow
.tooltip {
  max-width: 100%;
  pointer-events: none;

  .tooltip-in-modal & {
    z-index: $MODAL_Z_INDEX + 1;
  }

  .tooltip-inner {
    max-width: none;
  }
}
