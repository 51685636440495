@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.user-avatar {
  color: $white;
  font-size: 16px;
  font-weight: 300;
  height: auto;
  min-height: 36px;
  min-width: 36px;
  white-space: nowrap;
  width: 100%;
}
