@use 'sass:math';

@font-face {
  font-display: swap;
  font-family: 'Euclid Circular B';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/euclid/EuclidCircularB-Light-WebXL.woff2') format('woff2'), url('../fonts/euclid/EuclidCircularB-Light-WebXL.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Euclid Circular B';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/euclid/EuclidCircularB-Regular-WebXL.woff2') format('woff2'), url('../fonts/euclid/EuclidCircularB-Regular-WebXL.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Euclid Circular B';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/euclid/EuclidCircularB-Medium-WebXL.woff2') format('woff2'), url('../fonts/euclid/EuclidCircularB-Medium-WebXL.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Euclid Circular B';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/euclid/EuclidCircularB-Semibold-WebXL.woff2') format('woff2'), url('../fonts/euclid/EuclidCircularB-Semibold-WebXL.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Euclid Circular B';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/euclid/EuclidCircularB-Bold-WebXL.woff2') format('woff2'), url('../fonts/euclid/EuclidCircularB-Bold-WebXL.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Euclid Circular B';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/euclid/EuclidCircularB-LightItalic-WebXL.woff2') format('woff2'), url('../fonts/euclid/EuclidCircularB-LightItalic-WebXL.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Euclid Circular B';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/euclid/EuclidCircularB-RegularItalic-WebXL.woff2') format('woff2'), url('../fonts/euclid/EuclidCircularB-RegularItalic-WebXL.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Euclid Circular B';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/euclid/EuclidCircularB-MediumItalic-WebXL.woff2') format('woff2'), url('../fonts/euclid/EuclidCircularB-MediumItalic-WebXL.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Euclid Circular B';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/euclid/EuclidCircularB-SemiboldItalic-WebXL.woff2') format('woff2'), url('../fonts/euclid/EuclidCircularB-SemiboldItalic-WebXL.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Euclid Circular B';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/euclid/EuclidCircularB-BoldItalic-WebXL.woff2') format('woff2'), url('../fonts/euclid/EuclidCircularB-BoldItalic-WebXL.woff') format('woff');
}

@font-face {
  font-display: swap;
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/fira/fira-code-v21-latin-regular.woff2') format('woff2'), url('../fonts/fira/fira-code-v21-latin-regular.woff') format('woff');
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.btn,
body,
.form-control,
.custom-select,
.custom-file-label {
  font-feature-settings: 'tnum', 'ss01', 'cv08', 'calt', 'liga', 'kern';
}

// END Bootstrap declaration overwrites

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;

  .subhead {
    font-size: 0.8em;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

h1,
h2,
h3,
h4 {
  letter-spacing: 0;
}

.typography-card {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 10px;
  }
}

strong.h1,
strong.h2,
strong.h3,
strong.h4,
strong.h5,
strong.h6 {
  font-weight: $font-weight-bold;
}

.schedule-heading {
  border-top: 1px solid $border-color-light;
  color: $text-color-medium;
  margin: 0;
  padding: 16px 0;
}

p {
  font-size: $font-size-base;
  line-height: $line-height-base;

  @media screen and (min-width: $bp-md) {
    font-size: $font-size-base-md-up;
    line-height: math.div(20, 14);
  }
}

pre {
  margin: 0;
}

.highlight {
  background-color: $green;
  color: #FFF;
}

.section-heading {
  border-bottom: 1px solid $border-color;
  margin: 0 0 20px;
  padding-bottom: 6px;
}

.text-uppercase {
  letter-spacing: 0.2em;
}
