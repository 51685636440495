// Brand colors
$blue-900: #09204D;
$blue-800: #0C2D6D;
$blue-700: #103B8E;
$blue-600: #144FC2;
$blue-500: #1C62EB;
$blue-400: #3F79E9;
$blue-300: #76A0F0;
$blue-200: #BBD1FA;
$blue-100: #DFEAFF;
$blue-50: #F3F6FC;
$blue: $blue-500;

$brand-color: $blue;
$brand-color-darker: $blue-700;
$brand-color-lighter: $blue-300;

$peach-900: #242222;
$peach-800: #494342;
$peach-700: #6D605D;
$peach-600: #AA9088;
$peach-500: #CEA89A;
$peach-400: #F3C3AF;
$peach-300: #F5D0C1;
$peach-200: #F5DED5;
$peach-100: #FAEBE6;
$peach-50: #FEF8F6;
$peach: $peach-400;

$navy-900: #171A25;
$navy-800: #262C43;
$navy-700: #353E61;
$navy-600: #4C5577;
$navy-500: #606A90;
$navy-400: #888FAC;
$navy-300: #B1B5C6;
$navy-200: #CDD0DC;
$navy-100: #DFE1E9;
$navy-50: #F4F5FB;
$navy: $navy-900;

$gray-900: #1F2125;
$gray-800: #313236;
$gray-700: #484A51;
$gray-600: #5D5F66;
$gray-500: #777980;
$gray-400: #A3A4AB;
$gray-300: #BBBCC2;
$gray-200: #D3D4D9;
$gray-100: #F0F0F2;
$gray-50: #F8F8FA;

$green-900: #07321E;
$green-800: #0C5532;
$green-700: #127D4A;
$green-600: #169E5D;
$green-500: #1DB56C;
$green-400: #4ACF8F;
$green-300: #77E1AF;
$green-200: #AEEFD0;
$green-100: #D2F2E3;
$green-50: #E8F9F1;
$green: $green-500;

$red-900: #521818;
$red-800: #6C2020;
$red-700: #A13030;
$red-600: #BE3A3A;
$red-500: #D24747;
$red-400: #E96A6A;
$red-300: #F38282;
$red-200: #F9B1B1;
$red-100: #FBE2E2;
$red-50: #FCD1D1;
$red: $red-500;

$yellow-500: #EDEC38;
$yellow-300: #F5F470;
$yellow-100: #F7F79E;
$yellow-50: #FAF9BE;
$yellow: $yellow-500;

$orange-500: #D4780C;
$orange: $orange-500;

// Remaining Bootstrap colors
$purple: #AB6DC8;
$cyan: #38A0B9;
$pink: #D24ABC;
$indigo: #39147C;
$orange: #E77827;
$yellow: #F8E169;
$teal: #64C9B7;

$black: #171A25;
$black-secondary: rgba($black, 0.75);
$black-tertiary: rgba($black, 0.65);

$white: #FFF;
$white-secondary: rgba($white, 0.8);
$white-tertiary: rgba($white, 0.6);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8%;

// Links
//
// Style anchor elements.

$link-color: $blue;
$link-color-light: $cyan;
$link-color-darker: darken($link-color, $theme-color-interval);
$link-decoration: none;
$link-hover-color: lighten($link-color, ($theme-color-interval * 1.5));
$link-color-light: lighten($link-color-light, $theme-color-interval);
$link-hover-decoration: underline;

// Grays
$background-gray-dark-1: $navy-900;
$background-gray-dark-2: $navy-800;
$background-gray-dark-3: $navy-700;
$middle-gray: #808080;
$background-gray-light-1: $gray-50;
$background-gray-light-2: $gray-100;
$background-gray-light-3: $gray-200;
$cool-gray: $blue-50;

$border-color: $gray-300;
$border-color-light: $gray-200;
$border-color-extra-light: $gray-100;
$border-color-dark: #484848 !default;
$border-color-table: rgba(0, 0, 0, 0.05) !default;
$input-border-color: $gray-300;

$text-color: $black;
$text-color-medium: $black-secondary;
$text-color-muted: $gray-400;
$text-color-muted-on-dark: $white-secondary;

$chart-text-color: $text-color-muted;

// Mapping for EasyDev variables
$color-additional: $gray-100;
$color-additional-hover: $gray-200;

$color-gray: $color-additional;
$color-aside-background: $background-gray-dark-2;

$color-border: $border-color;
$color-border-dark: $background-gray-light-3;

$themes: (
  light: (
    topbarBackground: white,
    colorBackground: white,
    colorBackgroundBody:
    lighten($background-gray-light-1, $theme-color-interval),
    colorText: $text-color-muted,
    colorTextAdditional: lighten($text-color-muted, $theme-color-interval),
    logoImg: 'img/logo/storyteller-logo-black.svg',
    colorHover: $background-gray-light-1,
    colorBorder: $background-gray-light-1,
    colorIcon: $background-gray-light-2,
    imgInvert: invert(0%),
    colorFieldsBorder: #F2F4F7,
    colorFormRowBorder: #F2F4F7,
    colorBubble: rgba($background-gray-light-2, 0.65),
    colorBubbleActive: rgba($background-gray-light-2, 0.6),
    colorScrollbar: $background-gray-light-2,
    colorFitness: $background-gray-light-3,
    cardBackground: white,
    cardColorHover: $background-gray-light-1,
    cardText: $text-color-muted,
    cardTextSecondary: $background-gray-light-3,
    furnitureTextColor: $text-color-muted
  ),
  dark: (
    topbarBackground: $background-gray-dark-1,
    colorBackground: $background-gray-dark-2,
    colorBackgroundBody: #333,
    colorText: white,
    colorTextAdditional: $background-gray-dark-2,
    logoImg: 'img/logo/storyteller-logo-white.svg',
    colorHover: $background-gray-dark-3,
    colorBorder: $background-gray-light-2,
    colorIcon: $purple,
    imgInvert: invert(100%),
    colorFieldsBorder: $background-gray-light-3,
    colorFormRowBorder: $background-gray-light-3,
    colorBubble: rgba($background-gray-dark-2, 0.65),
    colorBubbleActive: rgba($background-gray-dark-2, 0.6),
    colorScrollbar: $background-gray-dark-2,
    colorFitness: white,
    cardBackground: $background-gray-dark-2,
    cardColorHover: $background-gray-dark-1,
    cardText: white,
    cardTextSecondary: $background-gray-light-3,
    furnitureTextColor: white
  ),
  lightondark: (
    topbarBackground: $background-gray-dark-1,
    colorBackground: $background-gray-dark-2,
    colorBackgroundBody: #333,
    colorText: white,
    colorTextAdditional: $background-gray-light-2,
    logoImg: 'img/logo/storyteller-logo-white.svg',
    colorHover: $background-gray-dark-2,
    colorBorder: $border-color,
    colorIcon: $blue,
    imgInvert: invert(0%),
    colorFieldsBorder: $color-border,
    colorFormRowBorder: white,
    colorBubble: rgba($background-gray-light-2, 0.65),
    colorBubbleActive: rgba($background-gray-light-2, 0.6),
    colorScrollbar: $background-gray-light-1,
    colorFitness: white,
    cardBackground: $background-gray-light-1,
    cardColorHover: white,
    cardText: $text-color,
    cardTextSecondary: $text-color-muted,
    furnitureTextColor: white
  )
);

// Bootstrap variables ---------------------------------------------------------------------------------

// Color system

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge((
  '50': $gray-50,
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
  '500': $gray-500,
  '600': $gray-600,
  '700': $gray-700,
  '800': $gray-800,
  '900': $gray-900
), $grays);

$primary: $green;
$secondary: $text-color-muted;
$success: $green;
$info: $blue;
$warning: $yellow;
$danger: $red;
$light: $background-gray-light-1;
$dark: $background-gray-dark-3;

$blue-lighter: #A680E4;
$indigo-lighter: #407DB6;
$purple-lighter: #FF74AE;
$pink-lighter: #FFA1BE;
$red-lighter: #F39682; // Old Value -> #FFAE4F - not used anywhere, leaving just in case
$orange-lighter: #FFEB3C;
$yellow-lighter: #ECF6AA;
$green-lighter: #5ADF67;
$teal-lighter: #92FCC9;
$cyan-lighter: #5EB5C6;
$light-lighter: $white;
$dark-lighter: $black;

$primary-lighter: $blue-lighter;
$secondary-lighter: lighten($text-color-muted, $theme-color-interval);
$success-lighter: $green-lighter;
$info-lighter: $brand-color-lighter;
$warning-lighter: $yellow-300;
$danger-lighter: $red-300;

$primary-darker: $blue-700;
$secondary-darker: $gray-800;
$success-darker: $green-700;
$info-darker: $brand-color-darker;
$warning-darker: $red-700;
$danger-darker: $red-700;
$light-darker: darken($background-gray-light-1, $theme-color-interval) !default;
$dark-darker: darken($background-gray-dark-1, $theme-color-interval) !default;

// For React-Select
$success-75: $green-200;
$success-50: $green-300;
$success-25: $green-400;

// Content headers
$header-highlight-background: linear-gradient(180deg, $blue, $blue-600 100%);

$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge((
  'blue': $blue,
  'indigo': $indigo,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'teal': $teal,
  'cyan': $cyan,
  'white': $white,
  'gray': $gray-500,
  'gray-dark': $gray-800,
  'red-lighter': $red-lighter,
), $colors);

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge((
  'primary': $primary,
  'secondary': $gray-400,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'dark-grey': $gray-700,
  'black-tertiary': $black-tertiary,
  'light-blue': $blue-300,
), $theme-colors);

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 180;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $text-color;
$yiq-text-light: $white;

// Body
// Settings for the `<body>` element.

$body-bg: $white;
$body-color: $text-color;

$component-active-color: $white;
$component-active-bg: theme-color('success');

$chart-color-1: $blue;
$chart-color-2: #9FBDF8;
$chart-color-3: $pink;
$chart-color-4: #FABAEF;
$chart-color-5: $red;
$chart-color-6: #F39682;
$chart-color-7: $orange;
$chart-color-8: $yellow;
$chart-color-9: $green;
$chart-color-10: #78F5BA;

// Export for js...
// import variables from 'scss/1-settings/colors.scss';

:export {
  // Priority colors
  primary: $blue;
  secondary: $text-color-muted;
  success: $green;
  info: $blue;
  warning: $yellow;
  danger: $red;
  light: $background-gray-light-1;
  dark: $background-gray-dark-1;
  // Brand colors
  purple: $purple;
  peach: $peach;
  yellow: $yellow;
  orange: $orange;
  green: $green;
  red: $red;
  cyan: $cyan;
  blue: $blue;
  pink: $pink;
  peach: $peach;
  // Chart colors
  chart-color-1: $chart-color-1;
  chart-color-2: $chart-color-2;
  chart-color-3: $chart-color-3;
  chart-color-4: $chart-color-4;
  chart-color-5: $chart-color-5;
  chart-color-6: $chart-color-6;
  chart-color-7: $chart-color-7;
  chart-color-8: $chart-color-8;
  chart-color-9: $chart-color-9;
  chart-color-0: $chart-color-10;
  blue-500: $blue-500;
  blue-100: $blue-100;
  blue-50: $blue-50;
  red-500: $red-500;
  red-50: $red-50;
  yellow-50: $yellow-50;
  navy-900: $navy-900;
  navy-600: $navy-600;
  gray-900: $gray-900;
  gray-800: $gray-800;
  gray-700: $gray-700;
  gray-400: $gray-400;
  gray-300: $gray-300;
  gray-200: $gray-200;
  gray-100: $gray-100;
  gray-50: $gray-50;
  black: $black;
  black-tertiary: $black-tertiary;
  black-secondary: $black-secondary;
  white-secondary: $white-secondary;
  red-lighter: $red-lighter;
}
