@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.public-page-container {
  align-items: center;
  background: $black radial-gradient(22% 6.5% at 50% 90%, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.15) 33%, rgba(0, 0, 0, 0) 100%), radial-gradient(92% 92% at 50% 92%, #444547 0%, #303133 100%);
  color: $white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 20px;

  @media screen and (min-width: $bp-md) {
    padding: 40px;
    overflow: auto;
  }

  .public-topbar {
    width: 100%;

    .logo {
      max-width: 50vw;

      @media screen and (max-width: $bp-lg) {
        margin-bottom: ($spacer * 2);
      }
    }
  }

  &.light-background {
    background: $cool-gray;
    color: $text-color;

    .topbar {
      box-shadow: 0;
    }
  }
}
