@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.chart-tooltip {
  background-color: $white;
  border-radius: 4px;
  border: 1px solid $background-gray-light-2;
  box-shadow: $box-shadow;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  width: auto;

  &.multiple {
    .info {
      border-bottom: 1px solid $background-gray-light-2;

      &:last-child {
        border-bottom: 0;
      }
    }

    h4 {
      font-size: $font-size-base;
      line-height: calc(16 / 14);
      margin-bottom: -4px;
    }
  }

  .header {
    background-color: $background-gray-light-2;
    text-transform: uppercase;
    padding: 6px 12px;
  }

  h3 {
    font-size: 10px;
    line-height: calc(12 / 10);
    letter-spacing: 0.14em;
    margin: 0;
  }

  .info {
    padding: 8px 12px;
  }

  h4 {
    font-weight: bold;
    font-size: 20px;
    line-height: 1;
    margin: 0;
  }

  small {
    font-size: 8px;
    line-height: calc(10 / 8);
    color: $black-tertiary;
    letter-spacing: 0.14em;
    text-transform: uppercase;
  }
}

.visx-tooltip-glyph {
  svg {
    width: 10px;
    height: 10px;
  }
}
