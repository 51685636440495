@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
li,
p {
  .popover-btn,
  .popover-btn:hover,
  .popover-btn:focus,
  .popover-btn:active {
    background-color: transparent;
    color: inherit;
    display: inline;
    height: 1em;
    line-height: 1;
    margin-top: -2px;
  }
}

.popover-body {
  p {
    margin-bottom: $popover-body-padding-y;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.modalPopover {
  .popover {
    z-index: $MODAL_Z_INDEX + 1;
  }
}
