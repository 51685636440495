@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

$thumb-size: 16px;

.timestamp-picker {
  position: relative;
  background-color: $gray-900;
  border-radius: $border-radius;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-content: center;
  max-height: 70vh;
}

.timestamp-picker-controls {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 16px;
  padding-top: 48px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  display: flex;
  align-items: center;
  gap: 16px;
}

.timestamp-picker-control-actions {
  display: flex;
  gap: 8px;
}

.timestamp-picker-control.btn.btn-icon {
  width: 48px;
  height: 36px;
}

.timestamp-picker-progress {

  /* Reset styles */
  appearance: none;
  background: $gray-200;
  border: none;
  cursor: pointer;
  flex: 1;
  position: relative;
  padding-inline: 0;

  /***** Track Styles *****/

  /* Chrome, Safari, Opera, and Edge Chromium */
  &::-webkit-slider-runnable-track {
    background: linear-gradient(90deg, $blue-600 var(--progress), $gray-200 var(--progress));
    border-radius: 8px;
    height: 8px;
  }

  /* Firefox */
  &::-moz-range-track {
    background: linear-gradient(90deg, $blue-600 var(--progress), $gray-200 var(--progress));
    border-radius: 8px;
    height: 8px;
  }

  /***** Thumb Styles *****/

  /* Chrome, Safari, Opera, Firefox, and Edge Chromium */
  &::-webkit-slider-thumb {
    opacity: 0;
    height: $thumb-size;
    width: $thumb-size;
  }

  /* Firefox */
  &::-moz-range-thumb {
    opacity: 0;
    height: $thumb-size;
    width: $thumb-size;
  }

  &:focus {

    /* Override input focus styles */
    background-color: $gray-200;
    box-shadow: none;

    &:after {
      background-color: $blue-600;
      outline: 3px solid $blue-600;
      outline-offset: 0.125rem;
    }
  }

  &:after {
    position: absolute;
    content: '';
    display: block;
    background-color: $gray-300;
    height: $thumb-size;
    width: $thumb-size;
    border-radius: 50%;
    border: 4px solid $white;
    left: var(--progress);
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
