@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.color-picker {
  .color-picker__color {
    margin: auto auto auto 0;
    padding-left: 10px;
  }

  .color-picker__button {
    border-radius: $border-radius;
    cursor: pointer;
    display: flex;
    height: 32px;
    padding: 0;
    transition: all 0.3s;
    width: 160px;

    @include themify($themes) {
      border: 1px solid themed('colorFieldsBorder');
    }

    &.active {
      border-color: $green;
    }
  }

  .color-picker__color-view {
    height: 30px;
    width: 50px;
  }

  .color-picker__popover.popover {
    width: auto;

    .block-picker {
      & > div:first-child {
        display: none;
      }
    }

    .block-picker,
    .chrome-picker,
    .sketch-picker {
      box-shadow: none !important;
    }
  }
}
