.container {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}

@if $responsive-gutters {

  // Responsive grid spacing, applying to all relevant Bootstrap classes
  @media screen and (min-width: $bp-md) {
    .row {
      margin-left: calc(-1 * #{$grid-gutter-width-md} / 2);
      margin-right: calc(-1 * #{$grid-gutter-width-md} / 2);

      &.no-gutters {
        margin-left: 0;
        margin-right: 0;
      }
    }

    %md-padding {
      padding-right: calc(#{$grid-gutter-width-md} / 2);
      padding-left: calc(#{$grid-gutter-width-md} / 2);
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      @for $i from 1 through $grid-columns {
        .col#{$infix}-#{$i} {
          @extend %md-padding;
        }
      }
    }

    .col,
    .col-auto,
    .container,
    .form-row > .col,
    .form-row > [class*='col-'] {
      @extend %md-padding;
    }
  }

  @media screen and (min-width: $bp-xl) {
    .row {
      margin-left: calc(-1 * #{$grid-gutter-width-xl} / 2);
      margin-right: calc(-1 * #{$grid-gutter-width-xl} / 2);

      &.no-gutters {
        margin-left: 0;
        margin-right: 0;
      }
    }

    %xl-padding {
      padding-right: calc(#{$grid-gutter-width-xl} / 2);
      padding-left: calc(#{$grid-gutter-width-xl} / 2);
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      @for $i from 1 through $grid-columns {
        .col#{$infix}-#{$i} {
          @extend %xl-padding;
        }
      }
    }

    .col,
    .col-auto,
    .container,
    .form-row > .col,
    .form-row > [class*='col-'] {
      @extend %xl-padding;
    }
  }
}
