@use 'sass:math';

@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.fieldset {
  margin-bottom: 20px;
  padding: 0;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  .fieldset-content {
    position: relative;
  }

  .collapsible legend,
  .collapsible .legend {
    padding-right: 40px;

    @media screen and (min-width: $bp-md) {
      padding-right: 60px;
    }
  }

  .legend-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    border-bottom: 1px solid $border-color;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    gap: 6px;
    padding-bottom: 7px;
    margin-bottom: 12px;

    .legend-wrapper-append {
      width: auto;
      z-index: 10;
    }

    legend,
    .legend {
      font-size: 20px;
      line-height: 1.4em;
      margin: 0;
      padding: 0;
      position: relative;
      z-index: 2;
      width: auto;

      @media screen and (min-width: $bp-md) {
        font-size: $h3-font-size;
        line-height: math.div(30, 24);
      }

      &.legend-sm {
        font-size: $font-size-base;
      }

      .tooltip-container {
        display: block;
        flex: 1;
      }

      input,
      input.form-control {
        background: transparent;
        border-radius: 0.1px;
        border: 0;
        font-size: inherit;
        height: auto;
        line-height: inherit;
        padding: 0;

        &.input-icon-left {
          padding-left: 1.25em;
        }

        + .form__form-group-error {
          @include gutter-sizing([ 'left' ], 0.5);
          top: 26px;
        }
      }

      .form__form-group-icon {
        background: transparent;
        border: 0;
        bottom: 6px;
        height: auto;
        padding: 0;
        top: 0;

        @media screen and (min-width: $bp-md) {
          bottom: 7px;
        }
      }
    }
  }

  &.padding-top {
    @include gutter-sizing('padding-top');
  }

  .fieldset-right-icon,
  .btn.icon.fieldset-right-icon {
    background-color: transparent;
    border-color: transparent;
    bottom: auto;
    color: inherit;
    font-size: 24px;
    height: 38px;
    margin: -19px -4px -4px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transition: transform 0.3s ease-in-out;
    width: 38px;
    z-index: 2;

    &:before {
      background-color: white;
      transition: all 0.3s ease-in-out;
    }

    &:focus {
      @include focus-shadow($red);
    }
  }

  &.closed .fieldset-right-icon,
  &.closed .btn.icon.fieldset-right-icon,
  &.closing .fieldset-right-icon,
  &.closing .btn.icon.fieldset-right-icon {
    transform: rotate(180deg);
  }
}

.tooltip.bs-tooltip-bottom {
  .arrow {
    left: 50% !important;
    margin-left: -7px !important;
  }
}
