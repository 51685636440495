@use 'sass:math';

@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

// Wizard Settings
$wizard-accent-color: $brand-color;
$step-number-size: 44px;

.wizard {
  .wizard-form-container {
    // screen height - (navbar and frame height, page padding, wizard nav height, wizard button height, wizard container bottom padding)
    min-height: calc(100vh - #{147px + 48px + 106px + 103px + 16px});
    padding-bottom: ($spacer * 2);

    @media screen and (min-width: $bp-md) {
      min-height: calc(100vh - #{147px + 40px + 106px + 103px + 16px});
    }

    @media screen and (min-width: $bp-xl) {
      min-height: calc(100vh - #{147px + 32px + 106px + 103px + 16px});
    }
  }

  legend {
    margin-bottom: 0;
  }

  .wizard-actions {
    @include gutter-sizing(['padding-top', 'padding-bottom']);
    position: sticky;
    bottom: 0;
    border-top: 1px solid #C3C3C3;
    display: flex;
    justify-content: space-between;
    background-color: $background-gray-light-1;
    z-index: 100;
    box-shadow: 0 -17px 22px -22px rgba($black, 0.2);
    margin-bottom: -20px;

    @media screen and (min-width: $bp-md) {
      margin-bottom: -24px;
    }
  }

  .btn-outline-secondary {
    color: $dark;
    border-color: $dark;

    &:hover,
    &:active,
    &:focus,
    &:not(:disabled):not(.disabled):active:focus {
      color: $white;
      background-color: $dark;
    }
  }

  .wizard-nav {
    @include gutter-sizing('padding-bottom');
    display: flex;
    flex-wrap: nowrap;

    .wizard-nav-item {
      flex-basis: 0;
      flex-grow: 1;
      font-weight: 600;
      padding: 0 1em;
      position: relative;
      width: 0; // Force equal width for each item
      z-index: 1;

      button {
        border-radius: 0.1px;
        display: block;
        margin: 0 auto;

        &:focus {
          outline: none;
          @include focus-shadow($wizard-accent-color);
        }
      }

      &:before,
      &:after {
        content: '';
        display: block;
        height: 2px;
        top: ($step-number-size * 0.5);
        left: 50%;
        position: absolute;
        width: 100%;
      }

      &:before {
        background: $wizard-accent-color;
        transform-origin: left;
        transform: scale(0);
        transition: all 300ms ease;
        z-index: 1;
      }

      &:after {
        background: $border-color-light;
        z-index: 0;
      }

      &:last-child:after {
        content: normal;
        display: none;
      }

      .step-number {
        align-items: center;
        background-color: $background-gray-light-1;
        border: 2px solid $border-color-light;
        display: flex;
        height: $step-number-size;
        justify-content: center;
        margin: 0 auto 12px;
        position: relative;
        transition: all 300ms ease;
        width: $step-number-size;
        z-index: 2;
      }

      .step-name {
        color: $text-color;
        display: block;
        font-size: 12px;
        font-weight: 500;
        height: math.div(14em, 12);
        letter-spacing: 0.025em;
        line-height: math.div(14, 12);
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        text-transform: uppercase;
        transition: color 300ms ease;
        white-space: nowrap;

        @media screen and (min-width: $bp-lg) {
          font-size: 16px;
          height: math.div(20em, 16);
          line-height: math.div(20, 16);
        }
      }

      &.disabled .step-name,
      &[disabled] .step-name {
        color: $text-muted;
      }

      &.active {
        .step-number {
          background-color: $wizard-accent-color;
          border-color: $wizard-accent-color;
          box-shadow: 0 0 0 2px $white, 0 0 0 4px $wizard-accent-color;
          color: $white;
          font-weight: $font-weight-bold;
        }

        .step-name {
          color: $wizard-accent-color;
        }
      }

      &.completed {
        .step-number {
          background-color: $wizard-accent-color;
          border-color: $wizard-accent-color;
          color: white;
        }

        &:before {
          transform: scale(1);
        }
      }
    }
  }
}
// }
