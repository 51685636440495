@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.error-404 {
  align-items: center;
  background-color: $cool-gray;
  display: flex;
  height: 100vh;
  justify-content: center;
  padding: 16px;
  width: 100vw;
  max-width: 100%;

  @media screen and (min-width: $bp-lg) {
    background: url('./img/404-background.jpg') center / cover no-repeat;
  }

  .row {
    flex: 1;

    @media screen and (max-width: $bp-md) {
      justify-content: center;
    }
  }

  .message {
    background-color: #FCFBFA;
    border-radius: $border-radius-lg;
    box-shadow: 0 80px 200px rgba(0, 0, 0, 0.1);
    padding: 32px 48px;
    text-align: center;

    @media screen and (min-width: $bp-md) {
      padding: 40px 60px;
    }

    @media screen and (min-width: $bp-xxl) {
      padding: 60px 100px;
    }

    .header-image {
      margin: 0 auto 40px;
    }

    p {
      margin-bottom: 2em;
    }

    .btn {
      min-width: 67%;
    }
  }
}
