// Extend the Bootstrap dropdown styles

// Transition
.dropdown-enter {
  opacity: 0;
}

.dropdown-enter-active {
  opacity: 1;
  transition: opacity 0.2s linear;
}

.dropdown-exit {
  opacity: 1;

  &.dropdown-menu {
    display: flex; // Keep the menu visible a bit longer
  }
}

.dropdown-exit-active {
  opacity: 0;
  transition: opacity 0.2s linear;
}
// End Transition

.dropdown > .btn:first-child {
  z-index: $zindex-dropdown + 1;
}

.dropdown-menu {
  flex-direction: column;
  box-shadow: $dropdown-box-shadow;
  color: white;
  padding: rem(10);

  &.show {
    display: flex;
  }

  .dropdown-header,
  .dropdown-item {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 4px;

      &:first-child {
        margin-bottom: 0;
      }
    }
  }

  .dropdown-header {
    border-bottom: 1px solid rgba($white, 0.2);
    font-size: 11px;
    letter-spacing: 0.2em;
    line-height: calc(14 / 11);
    margin: 14px 10px 12px;
    padding: 0 0 rem(5);
    text-transform: uppercase;

    &:first-child {
      margin-top: 10px;
    }
  }

  .dropdown-item {
    border-radius: $border-radius-sm;
    display: block; // Preventing weirdness with display: flex from bootstrap
    font-size: $font-size-base-md-up;
    line-height: calc(20 / 14);
    padding-bottom: rem(5); // Slight correction to match the designs, not sure why this is off with Inter
    transition: background-color 0.1s ease-in-out;

    &.active,
    &:active {
      background-color: $link-color-darker;
    }
  }
}
