.datetime-field {
  position: relative;
  width: 100%;

  .form__form-group {
    @media screen and (min-width: $bp-sm) {
      padding-bottom: 0;
    }
  }

  .time-inputs {
    display: flex;
    padding: 0 5px 10px;

    @media screen and (min-width: $bp-sm) {
      padding: 0 10px 20px;
    }

    .time-input-container {
      padding: 0 5px;
      flex: 50%;

      @media screen and (min-width: $bp-sm) {
        padding: 0 10px;
      }
    }
  }

  .SingleDatePicker,
  .DateInput,
  .DateInput_input {
    max-width: 100%;
    width: 100%;
  }

  &.contained:not(.date-picker-popover) {
    background-color: $white;
    border: 1px solid $border-color-light;
    border-radius: $border-radius;
    padding-top: 20px;

    .date-picker {
      overflow: hidden;
    }

    .DayPicker {
      background: transparent;
      margin: 0 auto;
      padding: 0 4px;
      position: relative;
      text-align: left;
      width: 100%!important;
    }
  }

  &.underlined {
    display: inline-block;

    .date-picker {
      margin: 0;
    }

    .DateInput_input {
      background: transparent;
      border-radius: 0.1px;
      border: 0;
      border-bottom: 1px solid $border-color;
      color: inherit;
      font-size: inherit;
      height: auto;
      line-height: inherit;
      max-width: 100%;
      padding: 0;
      width: 5.8em;

      &:focus {
        border-color: $text-color;
      }
    }

    .DateRangePickerInput_arrow {
      height: 24px;
      width: 24px;
    }
  }

  &.extra-info-top {
    .DayPicker {
      padding-top: 64px;
    }

    .extra-info {
      align-items: center;
      border-bottom: 1px solid $border-color-light;
      color: $text-color-medium;
      display: flex;
      height: 48px;
      justify-content: center;
      left: 0;
      overflow: hidden;
      padding: 4px 20px;
      position: absolute;
      text-align: center;
      top: 0;
      width: 100%;

      @media screen and (max-width: $bp-md) {
        font-size: 12px;
      }
    }
  }
}

// Date picker validation
.date-picker {
  margin: 0 -1px 20px;
  transition: margin-bottom 0.2s ease-in-out;

  &:last-child {
    margin-bottom: 0;
  }

  &.has-error {
    margin-bottom: 37px;
  }

  .validation {
    display: block;
    justify-self: center;
    margin: 6px 20px 0;
    position: static;
    text-align: center;
    width: auto;

    &:before,
    &:after {
      content: normal;
      display: none;
    }
  }

  .presets {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -6px;

    .btn {
      margin: 10px 6px 0;
    }
  }
}

// react-dates
.PresetDateRangePicker_panel {
  padding: 0 22px 11px;
}

.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  text-align: center;
  background: 0 0;
  border: 2px solid $info;
  color: $info;
  padding: 4px 12px;
  margin-right: 8px;
  font: inherit;
  font-weight: 700;
  line-height: normal;
  overflow: visible;
  box-sizing: border-box;
  cursor: pointer;
}

.PresetDateRangePicker_button:active {
  outline: 0;
}

.PresetDateRangePicker_button__selected {
  color: $white;
  background: $info;
}

.SingleDatePickerInput {
  display: inline-block;
}

.SingleDatePickerInput__withBorder {
  border-radius: 2px;
}

.SingleDatePickerInput__rtl {
  direction: rtl;
}

.SingleDatePickerInput__disabled {
  background-color: $background-gray-light-1;
}

.SingleDatePickerInput__block {
  display: block;
}

.SingleDatePickerInput__showClearDate {
  padding-right: $input-height-inner;
}

.SingleDatePickerInput_clearDate {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  border-radius: 50%;
}

.SingleDatePickerInput_clearDate__small {
  padding: 6px;
}

.SingleDatePickerInput_clearDate__hide {
  visibility: hidden;
}

.SingleDatePickerInput_clearDate_svg {
  fill: $gray-500;
  height: 12px;
  width: 15px;
  vertical-align: middle;
}

.SingleDatePickerInput_clearDate_svg__small {
  height: 9px;
}

.SingleDatePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px;
}

.SingleDatePickerInput_calendarIcon_svg {
  fill: $gray-500;
  height: 15px;
  width: 14px;
  vertical-align: middle;
}

.SingleDatePicker {
  position: relative;
  display: inline-block;
}

.SingleDatePicker__block {
  display: block;
}

.SingleDatePicker_picker {
  z-index: 1;
  position: absolute;

  @media screen and (min-width: $bp-lg) {
    left: var(--date-picker-left-offset, 0) !important;
  }
}

.SingleDatePicker_picker__rtl {
  direction: rtl;
}

.SingleDatePicker_picker__directionLeft {
  left: 0;
}

.SingleDatePicker_picker__directionRight {
  right: 0;
}

.SingleDatePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.SingleDatePicker_picker__fullScreenPortal {
  background-color: $white;
}

.SingleDatePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2;
}

.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: darken($text-color-muted, 10%);
  text-decoration: none;
}

.SingleDatePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: $text-color-muted;
}

.DayPickerKeyboardShortcuts_buttonReset {
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  font-size: 14px;
}

.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0;
}

.DayPickerKeyboardShortcuts_show {
  width: 33px;
  height: 26px;
  position: absolute;
  z-index: 2;
}

.DayPickerKeyboardShortcuts_show:before {
  content: '';
  display: block;
  position: absolute;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  bottom: 0;
  right: 0;
}

.DayPickerKeyboardShortcuts_show__bottomRight:before {
  border-top: 26px solid transparent;
  border-right: 33px solid $info;
  bottom: 0;
  right: 0;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover:before {
  border-right: 33px solid $info-darker;
}

.DayPickerKeyboardShortcuts_show__topRight {
  top: 0;
  right: 0;
}

.DayPickerKeyboardShortcuts_show__topRight:before {
  border-bottom: 26px solid transparent;
  border-right: 33px solid $info;
  top: 0;
  right: 0;
}

.DayPickerKeyboardShortcuts_show__topRight:hover:before {
  border-right: 33px solid $info-darker;
}

.DayPickerKeyboardShortcuts_show__topLeft {
  top: 0;
  left: 0;
}

.DayPickerKeyboardShortcuts_show__topLeft:before {
  border-bottom: 26px solid transparent;
  border-left: 33px solid $info;
  top: 0;
  left: 0;
}

.DayPickerKeyboardShortcuts_show__topLeft:hover:before {
  border-left: 33px solid $info-darker;
}

.DayPickerKeyboardShortcuts_showSpan {
  color: $white;
  position: absolute;
}

.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: 5px;
}

.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: 5px;
}

.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: 5px;
}

.DayPickerKeyboardShortcuts_panel {
  overflow: auto;
  border-radius: $border-radius-sm;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 22px;
  margin: 33px;
  text-align: left;
}

.DayPickerKeyboardShortcuts_title {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.DayPickerKeyboardShortcuts_list {
  list-style: none;
  padding: 0;
  font-size: 14px;
}

.DayPickerKeyboardShortcuts_close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 2;
}

.DayPickerKeyboardShortcuts_close:active {
  outline: 0;
}

.DayPickerKeyboardShortcuts_closeSvg {
  height: 15px;
  width: 15px;
  fill: $background-gray-light-3;
}

.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: $gray-500;
}

.CalendarDay {
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  text-align: center;
  width: auto !important;
  height: 40px !important;

  &.CalendarDay__selected:not(.CalendarDay__selected_start):not(.CalendarDay__selected_end) {
    border-radius: 20px;

    &:after {
      content: normal;
      display: none;
    }
  }

  &.CalendarDay__selected_start {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
  }

  &.CalendarDay__selected_end {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
  }

  &__today:after {
    border: 1px solid $border-color-light;
    border-radius: 20px;
    content: '';
    display: block;
    height: 40px;
    left: 50%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    z-index: 0;
  }

  &__selected_span:last-child,
  &__selected_start:last-child {
    &:not(.CalendarDay__selected_end):not(.CalendarDay__selected_start_no_selected_end):before {
      background: $info-lighter;
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: calc(100% - 20px);
      width: 20px;
      height: 100%;
      transform: translateX(20px);
    }
  }

  &__hovered_span:last-child {
    &:not(.CalendarDay__selected_end):not(.CalendarDay__selected_start_no_selected_end):not(:hover):before {
      background: $info-lighter;
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: calc(100% - 20px);
      width: 20px;
      height: 100%;
      transform: translateX(20px);
    }
  }

  &__selected_span:last-child:before,
  &__hovered_span:last-child:before {
    background-color: $info-lighter;
  }

  &__selected_start:last-child:before {
    background-color: $info;
  }

  &__selected_span:first-child,
  &__selected_end:first-child,
  &__hovered_span:first-child {
    &:not(.CalendarDay__selected_start):before {
      background: $info-lighter;
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      right: calc(100% - 20px);
      width: 20px;
      height: 100%;
      transform: translateX(-20px);
    }
  }

  &__selected_span:first-child:before,
  &__hovered_span:first-child:before {
    background-color: $info-lighter;
  }

  &__selected_end:first-child:before {
    background-color: $info;
  }

  &__hovered_span:after,
  &__selected_end:after,
  &__selected_span:after,
  &__selected_start:after {
    content: normal;
    display: none;
  }
}

.CalendarDay:active {
  outline: 0;
}

.CalendarDay__defaultCursor {
  cursor: default;
}

.CalendarDay__default {
  color: $text-color;
}

.CalendarDay__default:hover {
  background: $background-gray-light-1;
  color: inherit;

  // make after element so it's circular
  &:not(.CalendarDay__selected):not(.CalendarDay__hovered_span):not(.CalendarDay__selected_span) {
    border-radius: 20px;
  }
}

.CalendarDay__outside {
  border: 0;
  color: $background-gray-light-3;
  cursor: default;
  pointer-events: none;
}

.CalendarDay__outside:hover {
  border: 0;
  color: $background-gray-light-3;
}

.CalendarDay__blocked_minimum_nights {
  color: $background-gray-light-3;
}

.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  color: $background-gray-light-3;
}

.CalendarDay__highlighted_calendar {
  background: $warning-lighter;
  color: $text-color;
}

.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: $warning;
  color: $text-color;
}

.CalendarDay__selected_span {
  background: $info-lighter;
  color: $white;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: $info;
  border-radius: 0;
  color: $white;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: $info;
  color: $white;
}

.CalendarDay__hovered_span {
  background: $info-lighter;
  color: $white;

  &:hover {
    background: $info-lighter;
    color: $white;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
  }
}

.CalendarDay__hovered_span:active {
  background: shade($info-lighter, 5%);
  color: $white;
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: transparent;
  color: $gray-500;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  color: $background-gray-light-3;
}

.CalendarDay__hovered_start_first_possible_end {
  background: $background-gray-light-1;
}

.CalendarDay__hovered_start_blocked_min_nights {
  background: $background-gray-light-1;
}

.CalendarMonth {
  text-align: center;
  vertical-align: top;
  user-select: none;
}

.CalendarMonth_table {
  border-collapse: separate;
  border-spacing: 0 2px;
  width: 100%;
}

.CalendarMonth_caption {
  caption-side: initial;
  color: $text-color;
  font-size: 18px;
  margin-top: 1px;
  padding-bottom: 48px;
  text-align: center;

  strong {
    font-weight: normal;
  }
}

.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px;
}

.CalendarMonthGrid {
  background: inherit;
  max-width: 100%;
  opacity: 1;
  text-align: left;
  z-index: 0;
}

.CalendarMonthGrid__animating {
  z-index: 1;
}

.CalendarMonthGrid__horizontal {
  position: absolute;
}

.CalendarMonthGrid__vertical {
  margin: 0 auto;
}

.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
  overflow-y: scroll;
}

.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%;
  transition: opacity 0.3s ease;
  width: 100%;
}

.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.CalendarMonthGrid_month__hidden {
  visibility: hidden;
  opacity: 0;
}

.DayPickerNavigation {
  position: relative;
  z-index: 2;
}

.DayPickerNavigation__horizontal {
  height: 0;
}

.DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  bottom: 0;
  left: 0;
}

.DayPickerNavigation__verticalScrollableDefault {
  position: relative;
}

.DayPickerNavigation__bottom {
  height: auto;
}

.DayPickerNavigation__bottomDefault {
  display: flex;
  justify-content: space-between;
}

.DayPickerNavigation_button {
  cursor: pointer;
  user-select: none;
  border: 0;
  padding: 0;
  margin: 0;
}

.DayPickerNavigation_button__default {
  background-color: $white;
  color: $text-color;
}

.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  background: $background-gray-light-1;
}

.DayPickerNavigation_button__default:active {
  background: $background-gray-light-1;
}

.DayPickerNavigation_button__disabled {
  cursor: default;
}

.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
  background: $background-gray-light-1;
}

.DayPickerNavigation_button__disabled:active {
  background: 0 0;
}

.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  line-height: 0.78;
  border-radius: $border-radius;
  padding: 4px 9px;
}

.DayPickerNavigation_bottomButton__horizontalDefault {
  position: static;
  margin: -10px 22px 30px;
}

.DayPickerNavigation_leftButton__horizontalDefault {
  left: 12px;
}

.DayPickerNavigation_rightButton__horizontalDefault {
  right: 12px;
}

.DayPickerNavigation_button__verticalDefault {
  padding: 5px;
  box-shadow: $box-shadow-sm;
  position: relative;
  display: inline-block;
  text-align: center;
  height: 100%;
  width: 50%;
}

.DayPickerNavigation_nextButton__verticalDefault {
  border-left: 0;
}

.DayPickerNavigation_nextButton__verticalScrollableDefault {
  width: 100%;
}

.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: $text-color;
  display: block;
}

.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
  fill: $text-color;
}

.DayPickerNavigation_svg__disabled {
  fill: $background-gray-light-1;
}

.DayPicker__verticalScrollable {
  height: 100%;
}

.DayPicker__hidden {
  visibility: hidden;
}

.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%;
}

.DayPicker_portal__vertical {
  position: initial;
}

.DayPicker_focusRegion {
  outline: 0;
}

.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top;
}

.DayPicker_weekHeaders {
  position: relative;
}

.DayPicker_weekHeader {
  color: $text-color;
  position: absolute;
  top: 44px;
  z-index: 2;
  text-align: left;
  width: 100%;

  + .DayPicker_weekHeader {
    display: none;
  }
}

.DayPicker_weekHeader__vertical {
  left: 50%;
}

.DayPicker_weekHeader__verticalScrollable {
  top: 0;
  display: table-row;
  border-bottom: 1px solid $white;
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.DayPicker_weekHeader_ul {
  display: flex;
  justify-content: space-around;
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
}

.DayPicker_weekHeader_li {
  color: $text-color-muted;
  display: inline-block;
  text-align: center;
}

.DayPicker_transitionContainer {
  position: relative;
  overflow-x: visible;
  border-radius: $border-radius;
}

.DayPicker_transitionContainer__horizontal {
  transition: height 0.2s ease-in-out;
}

.DayPicker_transitionContainer__vertical {
  width: 100%;
}

.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll;
}

.DateInput {
  display: block;
  height: 100%;
}

.DateInput input,
.DateInput input:not(:last-child) {
  padding: 0.375rem 0.75rem;
  width: 7.7em;
}

// Extra specific to overwrite input icon styles
.DateInput input:not(:last-child) {
  padding: 0.375rem 0.75rem;
}

.DateRangePicker__block .DateInput {
  display: block;
  flex: 1;
  width: 100%;

  input {
    width: 100%;
  }
}

.DateInput_input__readOnly {
  user-select: none;
}

.DateInput_screenReaderMessage {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.DateInput_fang {
  display: none;
}

.SingleDatePickerInput,
.DateRangePickerInput {
  align-items: center;
  border: 0;
  display: flex;
}

.contained .DayPicker {
  box-shadow: none;
}

.contained .DateRangePickerInput,
.contained .SingleDatePickerInput {
  background-color: $input-bg;
  border-radius: $input-border-radius;
  border: $input-border-width solid $input-border-color;
  height: $input-height;

  .DateInput {
    position: relative;
    margin: 0 6px;

    input:focus {
      box-shadow: none;
    }

    input {
      border: 0;
      height: 100%;
      padding: 0;
      width: 82px;
    }

    &:after {
      background-color: $blue;
      bottom: 0;
      content: '';
      display: block;
      height: 2px;
      left: 0;
      opacity: 0;
      position: absolute;
      transform: scaleY(0);
      transition: opacity 0.2s linear, transform 0.2s ease-in-out;
      width: 100%;
    }

    &:focus-within:after {
      opacity: 1;
      transform: scaleY(1);
    }
  }
}

.DateRangePickerInput__disabled {
  background: $background-gray-light-1;
}

.DateRangePickerInput__withBorder {
  border-radius: $input-border-radius;
}

.DateRangePickerInput__rtl {
  direction: rtl;
}

.DateRangePickerInput__showClearDates {
  padding-right: 24px;
}

.DateRangePickerInput_arrow {
  align-items: center;
  color: $text-color;
  display: flex;
  justify-content: center;
  margin: 0;
}

.DateRangePickerInput_arrow_svg {
  fill: $text-color;
  height: 19px;
  width: 19px;
}

.DateRangePickerInput_clearDates {
  align-items: center;
  border-radius: 30px;
  background-color: $text-color-muted;
  border: 0;
  cursor: pointer;
  display: flex;
  height: 17px;
  justify-content: center;
  margin: 0;
  overflow: visible;
  position: absolute;
  padding: 0;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  width: 17px;
}

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  border-radius: 50%;
}

.DateRangePickerInput_clearDates__hide {
  visibility: hidden;
}

.DateRangePickerInput_clearDates_svg {
  fill: $white;
  height: 9px;
  width: 9px;
}

.DateRangePickerInput_calendarIcon,
.SingleDatePickerInput_calendarIcon {
  align-items: center;
  background: 0 0;
  border: 0;
  border-right: $input-border-width solid $input-border-color;
  color: inherit;
  cursor: pointer;
  display: flex;
  height: $input-height;
  justify-content: center;
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: $input-height;
}

.DateRangePickerInput_calendarIcon_svg {
  fill: $gray-500;
  height: 15px;
  width: 14px;
  vertical-align: middle;
}

.DateRangePicker {
  position: relative;
  display: inline-block;
}

.DateRangePicker__block {
  display: block;
}

.SingleDatePicker_picker,
.DateRangePicker_picker {
  z-index: 11;
  position: absolute;
  top: 100% !important;
  border-radius: $border-radius;
  max-height: var(--date-picker-max-height, auto);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
}

.down {
  .SingleDatePicker_picker,
  .DateRangePicker_picker {
    top: 130% !important;

    &:after {
      content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10" fill="none"><path d="M10 0L0 10H20L10 0Z" fill="white"/></svg>');
      background-color: transparent;
      position: absolute;
      top: -15px;
      left: 10px;

      @media screen and (min-width: $bp-lg) {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.up {
  .SingleDatePicker_picker,
  .DateRangePicker_picker {
    top: inherit !important;
    bottom: 130% !important;

    &:after {
      content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10" fill="none"><path d="M10 0L0 10H20L10 0Z" fill="%23F3F6FC"/></svg>');
      transform: rotate(180deg);
      position: absolute;
      bottom: -15px;
      left: 10px;

      @media screen and (min-width: $bp-lg) {
        left: 50%;
        transform: translateX(-50%) rotate(180deg);
      }
    }
  }
}

.DateRangePicker_picker__rtl {
  direction: rtl;
}

.DateRangePicker_picker__directionLeft {
  left: 0;
}

.DateRangePicker_picker__directionRight {
  right: 0;
}

.DateRangePicker_picker__portal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.DateRangePicker_picker__fullScreenPortal {
  background-color: $white;
}

.DayPicker {
  background-color: $white;
  border-radius: $border-radius;
  box-shadow: $box-shadow-lg;
  max-width: 100vw;
  padding: 16px 8px;
  overflow: hidden;

  .SingleDatePicker & {
    @media screen and (min-width: $bp-md) {
      min-width: 320px;
    }
  }

  .DateRangePicker & {
    @media screen and (min-width: $bp-md) {
      min-width: 344px;
    }
  }

  div[style*='width'] {
    width: 100% !important;
  }

  .DayPickerNavigation_leftButton__horizontalDefault {
    left: 0;
  }

  .DayPickerNavigation_rightButton__horizontalDefault {
    right: 0;
  }
}

.DateRangePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2;
}

.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: darken($background-gray-light-3, 10%);
  text-decoration: none;
}

.DateRangePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: $background-gray-light-3;
}
