$chart-text-color: $border-color !default;
$chart-text-opacity: 0.7 !default;
$chart-font-size: $font-size-sm !default;
$chart-font-weight: $font-weight-normal !default;

$chart-line-opacity: 1 !default;
$chart-line-color: $background-gray-light-3 !default;

$chart-tooltip-fill: $middle-gray !default;
$chart-tooltip-fill-opacity: 0.075 !default;

$chart-legend-padding: $spacer 0 0 !default;
$chart-legend-list-style: none !default;
$chart-legend-border-radius: 50% !default;
$chart-legend-height: 0.8em !default;
$chart-legend-width: $chart-legend-height !default;

.chart-container {
  position: relative;
}

.chart {
  .recharts-text {
    fill: $chart-text-color;
    font-size: $chart-font-size;
    font-weight: $chart-font-weight;
    opacity: $chart-text-opacity;
  }

  .recharts-cartesian-axis-line,
  .recharts-cartesian-axis-tick-line,
  .recharts-cartesian-grid-vertical line,
  .recharts-cartesian-grid-horizontal line {
    fill: $chart-line-color;
    fill-opacity: $chart-line-opacity;
    stroke: $chart-line-color;
  }

  .recharts-tooltip-cursor {
    fill: $chart-tooltip-fill;
    fill-opacity: $chart-tooltip-fill-opacity;
    stroke: rgba($chart-tooltip-fill, $chart-tooltip-fill-opacity);
  }

  .recharts-brush-slide {
    fill: $chart-line-color;
    fill-opacity: $chart-line-opacity;
  }

  .recharts-brush-traveller {
    transform: translateX(-8px);

    rect {
      fill: $white;
      stroke: $background-gray-light-2;
      width: 12px;
    }

    line {
      stroke: $background-gray-light-2;
      transform: translateX(4px);
    }

    &:first-of-type {
      transform: translateX(0);
    }
  }

  .recharts-legend-wrapper {
    color: $text-color-medium;
  }

  .recharts-default-tooltip {
    background-color: $white;
    border-radius: $border-radius;
    border: 1px solid $border-color-light;
    padding: ($spacer * 0.5);
    white-space: nowrap;

    .recharts-total-tooltip {
      margin: 0;
    }
  }

  .chart-legend {
    padding: $chart-legend-padding;
    list-style: $chart-legend-list-style;

    li {
      margin-bottom: ($spacer * 0.5);
    }

    .legend-color-sample {
      border-radius: $chart-legend-border-radius;
      display: inline-block;
      height: $chart-legend-height;
      margin-right: 0.5em;
      width: $chart-legend-width;
    }
  }
}
