// Making some variables accessible everywhere
$bp-sm: 570px !default;
$bp-md: 760px !default;
$bp-lg: 992px !default;
$bp-xl: 1200px !default;
$bp-xxl: 1920px !default;

// Overwriting bootstrap settings
$grid-columns: 12 !default;
$grid-gutter-width: 16px !default;
$grid-gutter-width-md: 20px !default;
$grid-gutter-width-xl: 30px !default;

// Adding to this map will update anywhere that uses the gutter sizing mixin
// The notable exception is in the objects/layout.scss,
// where we need to account for top/side bar widths
$grid-gutters: (
  0: $grid-gutter-width,
  $bp-md: $grid-gutter-width-md,
  $bp-xl: $grid-gutter-width-xl
) !default;

// Topbar height
$topbar-height: 60px !default;

// Sidebar
$sidebar-expanded-width: 236px !default;
$sidebar-collapsed-width: 60px !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: $bp-sm,
  md: $bp-md,
  lg: $bp-lg,
  xl: $bp-xl,
  xxl: $bp-xxl
) !default;

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-width: 1664px !default;

$container-max-widths: (
  sm: $container-max-width
) !default;

@include _assert-ascending($container-max-widths, '$container-max-widths');

// Fill the screen with a page
$min-page-height: calc(100vh - #{$topbar-height + 30px}) !default;
