$card-border: 0 !default;
$card-shadow: $box-shadow !default;
$card-in-card-default-background: $white !default;

$main-card-shadow: $box-shadow-dark !default;
$main-card-bottom-margin: 14px !default;
$card-padding-md-up: 20px !default;
$card-header-footer-padding-md-up: 12px 20px !default;

.card {
  border: $card-border;
  box-shadow: $card-shadow;
  color: $text-color;
  overflow: visible;

  .card {
    background-color: $card-in-card-default-background;
  }

  &.top-border {
    border-top: $border-radius-lg solid;
  }
}

// Removing additional padding added by Bootstrap
.card-img-overlay {
  padding: 0;
}

.card-body {
  @media screen and (min-width: $bp-md) {
    padding: $card-padding-md-up;
  }
}

.card-header,
.card-footer {
  @media screen and (min-width: $bp-md) {
    padding: $card-header-footer-padding-md-up;
  }
}
