$font-family-sans-serif: 'Euclid Circular B', -apple-system, blinkmacsystemfont,
  'Segoe UI', roboto, 'Helvetica Neue', arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-monospace: 'Fira Code', sfmono-regular, menlo, monaco, consolas,
  'Liberation Mono', 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-weight-base: $font-weight-normal;
