// Responsive font sizes

@if $responsive-font-sizes {
  p {
    font-size: $font-size-base;
    line-height: $line-height-base;

    @media screen and (min-width: $bp-md) {
      font-size: $font-size-base-md-up;
      line-height: calc(20 / 14);
    }
  }
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
  letter-spacing: $headings-letter-spacing;
}

@if $no-bottom-margin-last-child {
  p:last-child,
  h1:last-child,
  h2:last-child,
  h3:last-child,
  h4:last-child,
  h5:last-child,
  h6:last-child,
  .h1:last-child,
  .h2:last-child,
  .h3:last-child,
  .h4:last-child,
  .h5:last-child,
  .h6:last-child {
    margin-bottom: 0;
  }
}
