@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.collapse-container {
  .title {
    margin: 0;
  }

  .trigger {
    width: 100%;

    &:disabled,
    &.disabled {
      cursor: default;
      opacity: 0.5;
    }
  }

  .trigger-icon {
    position: absolute;
    right: 0;
    transition: transform $transition-duration ease-in-out;
    transform: translate(-50%, -50%) rotate(0deg);
    top: 50%;
  }

  .open .trigger-icon {
    transform: translate(-50%, -50%) rotate(180deg);
    transform-origin: center;
  }

  .collapse-content {
    padding-top: $spacer;
  }
}
