$grid-border-radius: 3px;
$separator-padding: 5px;

.actions-grid {
  &[data-grid-actions='1'] {
    button {
      border-radius: $grid-border-radius;
    }
  }

  &:not([data-grid-actions='1']) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0;
    z-index: 1;

    // Add bg color to container in case there's an uneven # of buttons
    background-color: $gray-50;
    border-radius: $grid-border-radius;

    button {
      border-radius: 0;
      margin-left: 0;

      &:focus {
        // Ensure outline is on top of any grid lines
        z-index: 6;
      }
    }

    button.btn:after {
      display: none;
    }

    & > button:nth-child(1),
    .tooltip-container:nth-child(1) button {
      border-top-left-radius: $grid-border-radius;
    }

    & > button:nth-child(2),
    .tooltip-container:nth-child(2) button {
      border-top-right-radius: $grid-border-radius;
    }

    & > button:nth-last-of-type(2),
    .tooltip-container:nth-last-of-type(2) button {
      border-bottom-left-radius: $grid-border-radius;
    }

    & > button:nth-last-of-type(1):nth-of-type(even),
    .tooltip-container:nth-last-of-type(1):nth-of-type(even) button {
      border-bottom-right-radius: $grid-border-radius;
    }

    // If there's an uneven # of buttons, the last button might have an odd index
    // In that case, we don't want it to have a curved radius on the right
    & > button:nth-last-of-type(1):nth-of-type(odd),
    .tooltip-container:nth-last-of-type(1):nth-of-type(odd) button {
      border-bottom-left-radius: $grid-border-radius;
    }

    div[class$='-separatorV'] {
      position: absolute;
      width: 1px;
      height: calc(100% - ($separator-padding * 2));
      background: $gray-200;
      left: calc(50% - 1px / 2);
      top: $separator-padding;
      z-index: 5;
    }
  }

  &:not([data-grid-actions='1']):not([data-grid-actions='2']) {
    div[class*='-separatorH'] {
      position: absolute;
      height: 1px;
      width: calc(100% - ($separator-padding * 2));
      background: $gray-200;
      left: $separator-padding;
      z-index: 5;
    }

    div[class$='-separatorH'] {
      top: calc(50% - 1px / 2);
    }

    @for $i from 1 through 2 {
      div[class$='-separatorH#{$i}'] {
        top: calc(#{$i} / 3 * 100% - 1px / 2);
      }
    }
  }
}
