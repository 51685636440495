@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.overallAnalyticsStatsTableWrapper {
  table.dataTable {
    color: $black-secondary;
  }

  tbody.dataTableBody {
    tr {
      height: 48px;

      td {
        h4.empty {
          color: $gray-400;
        }
      }
    }
  }

  thead.dataTableHeader {
    th {
      font-weight: 600;

      .active {
        color: $black;
      }

      svg {
        margin-left: 8px;
      }
    }
  }

  .pagination {
    display: none;
  }

  .tableWrapper {
    width: calc(100% + 40px);
    margin-left: -20px;
    overflow-x: auto;
  }
}
