// Tests background colors to make the default
// text color dark or light depending on contrast
@each $color, $value in $theme-colors {
  .bg-#{$color} {
    color: color-yiq($value);
  }
}

@each $color, $value in $colors {
  .bg-#{$color} {
    color: color-yiq($value);
  }
}

// Background color without gradient

.bg-blue {
  background-color: $blue;
}

.bg-indigo {
  background-color: $indigo;
}

.bg-purple {
  background-color: $purple;
}

.bg-pink {
  background-color: $pink;
}

.bg-red,
.bg-danger {
  background-color: $red;
}

.bg-red-lighter {
  background-color: $red-lighter;
}

.bg-orange,
.bg-warning {
  background-color: $orange;
}

.bg-yellow {
  background-color: $yellow;
}

.bg-green,
.bg-primary,
.bg-success {
  background-color: $green;
}

.bg-teal {
  background-color: $teal;
}

.bg-cyan,
.bg-info {
  background-color: $cyan;
}

.bg-light {
  background-color: $light;
}

.bg-dark {
  background-color: $dark;
}

// Background color gradients

.bg-blue.gradient {
  background-image: linear-gradient(to top right, $blue, $blue-lighter);
}

.bg-indigo.gradient {
  background-image: linear-gradient(to top right, $indigo, $indigo-lighter);
}

.bg-purple.gradient {
  background-image: linear-gradient(to top right, $purple, $purple-lighter);
}

.bg-pink.gradient {
  background-image: linear-gradient(to top right, $pink, $pink-lighter);
}

.bg-red.gradient,
.bg-danger.gradient {
  background-image: linear-gradient(to top right, $red, $red-lighter);
}

.bg-orange.gradient,
.bg-warning.gradient {
  background-image: linear-gradient(to top right, $orange, $orange-lighter);
}

.bg-yellow.gradient {
  background-image: linear-gradient(to top right, $yellow, $yellow-lighter);
}

.bg-green.gradient,
.bg-primary.gradient,
.bg-success.gradient {
  background-image: linear-gradient(to top right, $green, $green-lighter);
}

.bg-teal.gradient {
  background-image: linear-gradient(to top right, $teal, $teal-lighter);
}

.bg-cyan.gradient,
.bg-info.gradient {
  background-image: linear-gradient(to top right, $cyan, $cyan-lighter);
}

.bg-light.gradient {
  background-image: linear-gradient(to top right, $light, $light-lighter);
}

.bg-dark.gradient {
  background-image: linear-gradient(to top right, $dark, $dark-lighter);
}
