@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.blank {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  .global-sidebar {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba($white, 0) 0%, rgba($white, 0.05) 50%, rgba($white, 0) 100%);
      animation: glow 1.5s infinite;
    }
  }
}

.blank-loading {
  background-color: rgb(23, 26, 37);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@keyframes glow {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(200%);
  }
}
