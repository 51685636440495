@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.panel.blank-slate {
  align-items: center;
  box-shadow: none;
  justify-content: center;

  h2 {
    font-size: 30px;
    font-weight: 700;
    line-height: calc(36 / 30);
    margin: 0 auto 26px;
    max-width: 600px;
  }

  p {
    margin: 0 auto 30px;
    max-width: 600px;
  }

  &.card {
    background-color: transparent;
    margin-bottom: 0;
  }

  &.blank-page-notice {
    @media screen and (min-height: 900px) {
      padding-top: 20px;
    }

    @media screen and (min-height: 1100px) {
      padding-top: 60px;
    }

    @media screen and (min-height: 1300px) {
      padding-top: 100px;
    }

    .artwork {
      width: 768px;
    }
  }

  &.no-results,
  &.blank-page-notice {
    align-items: center;
    color: $text-color-medium;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  &.no-results {
    margin-top: 40px;

    p {
      max-width: 324px;
    }

    .artwork {
      width: 164px;
    }
  }

  .card-title,
  .card-text,
  .card-body {
    text-align: center;
    text-transform: none;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  p.card-text {
    max-width: 26rem;
    margin-bottom: 1.5em !important;
    color: $black-tertiary;
  }

  .artwork {
    margin: 0 auto 32px;

    @media screen and (min-width: $bp-md) {
      margin: 0 auto 50px;
    }
  }
}
