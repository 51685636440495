@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.topbar {
  margin-bottom: 20px;
  align-items: center;
  gap: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-direction: row;
  gap: 30px;

  .filter-wrapper {
    display: flex;
    gap: 10px 30px;
    align-items: center;
    flex: 1 1 0;
    flex-wrap: wrap;
    min-width: 0;
  }

  &.isDisabled {
    opacity: 0.5;
    pointer-events: none;

    input {
      background: #FFF;
    }

    .rs__single-value--is-disabled {
      color: $black;
    }

    .rs__control--is-disabled {
      background: #FFF;

      .rs__input-container {
        color: $black;
      }
    }
  }

  .labeled-toggle-container {
    flex: 0 1 auto;

    .select-field-async-paginate {
      min-width: 200px;
    }

    &.filter-dropdown {
      max-width: 230px;
      width: 100%;

      .select-field-async-paginate {
        width: 100%;
      }
    }
  }
}

.buttons-section {
  display: flex;
  flex: 0 0 auto;
  gap: 15px;
  white-space: nowrap;
}

.sortingOptionsContainer {
  min-width: 232px;
}

.sortingLabel {
  margin-right: 10px;
  display: inline-block;
}

.categories-filter {
  width: 350px;
}

.search-bar {
  max-width: 376px;
  flex: 1 1 auto;
}

@media screen and (max-width: $bp-lg) {

  .topbar {
    align-items: flex-end;
    flex-direction: column-reverse;
    gap: 20px;

    .filter-wrapper {
      width: 100%;
    }
  }

  /** Use between items to ensure they're always aligned to the edges */
  .topbar-spacer {
    flex: 1;
  }

  .buttons-section {
    gap: 12px;
  }
}

@media screen and (max-width: $bp-md) {
  .topbar {
    .labeled-toggle-container {
      width: 100%;

      &.filter-dropdown {
        max-width: 100%;
      }

      .SelectField,
      .sortingOptionsContainer {
        width: 100%;
      }

      .sortingLabel {
        flex: 1 0 auto;
      }
    }

    .pageInfo {
      width: 100%;
    }

    .search-bar {
      max-width: 100%;
    }
  }
}
