@import 'react-image-crop/src/ReactCrop.scss';
@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.image-cropper {
  position: relative;
  background-color: $gray-900;
  border-radius: $border-radius;
  display: flex;
  justify-content: center;
  align-content: center;
  max-height: 70vh;
}

.ReactCrop__crop-selection {
  border: 1px solid $blue-300;
  background-image: none !important;
}

.ReactCrop__drag-handle {
  &:after {
    all: unset; // Reset styles
    display: block;
    content: '';
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.ord-nw {
    margin-top: -3px;
    margin-left: -3px;

    &:after {
      background: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.45455 0.5C1.37508 0.5 0.5 1.37508 0.5 2.45455L0.5 15.5455C0.5 16.6249 1.37508 17.5 2.45455 17.5C3.53401 17.5 4.40909 16.6249 4.40909 15.5455L4.40909 4.40909L15.5455 4.40909C16.6249 4.40909 17.5 3.53401 17.5 2.45455C17.5 1.37508 16.6249 0.5 15.5455 0.5L2.45455 0.5Z' fill='%231F2125' stroke='%2376A0F0' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    }
  }

  &.ord-ne {
    margin-top: -3px;
    margin-right: -3px;

    &:after {
      background: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.5 2.45455C17.5 1.37508 16.6249 0.5 15.5455 0.5L2.45455 0.5C1.37508 0.5 0.5 1.37508 0.5 2.45455C0.5 3.53401 1.37508 4.40909 2.45455 4.40909L13.5909 4.40909L13.5909 15.5455C13.5909 16.6249 14.466 17.5 15.5455 17.5C16.6249 17.5 17.5 16.6249 17.5 15.5455L17.5 2.45455Z' fill='%231F2125' stroke='%2376A0F0' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    }
  }

  &.ord-sw {
    margin-bottom: -3px;
    margin-left: -3px;

    &:after {
      background: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.45455 17.5C1.37508 17.5 0.5 16.6249 0.5 15.5455L0.5 2.45455C0.5 1.37508 1.37508 0.5 2.45455 0.5C3.53401 0.5 4.40909 1.37508 4.40909 2.45455L4.40909 13.5909H15.5455C16.6249 13.5909 17.5 14.466 17.5 15.5455C17.5 16.6249 16.6249 17.5 15.5455 17.5H2.45455Z' fill='%231F2125' stroke='%2376A0F0' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    }
  }

  &.ord-se {
    margin-bottom: -3px;
    margin-right: -3px;

    &:after {
      background: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.5 15.5455C17.5 16.6249 16.6249 17.5 15.5455 17.5L2.45455 17.5C1.37508 17.5 0.5 16.6249 0.5 15.5455C0.5 14.466 1.37508 13.5909 2.45455 13.5909L13.5909 13.5909L13.5909 2.45455C13.5909 1.37508 14.466 0.5 15.5455 0.5C16.6249 0.5 17.5 1.37508 17.5 2.45455L17.5 15.5455Z' fill='%231F2125' stroke='%2376A0F0' stroke-linecap='round'/%3E%3C/svg%3E%0A");
    }
  }
}
