@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

// Animation styles, order is important here
.loading-enter {
  opacity: 0;
}

.loading-enter-active {
  opacity: 1;
  transition: opacity 0.2s linear;
}

.loading-exit {
  opacity: 1;
}

.loading-exit-active {
  opacity: 0;
  transition: opacity 0.2s linear;
}

// End animation styles

.loading-container {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  &.mask-container {
    background-color: rgba($white, $modal-backdrop-opacity);
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10;

    &.dark-mask {
      background-color: rgba($background-gray-dark-1, $modal-backdrop-opacity);

      .load-icon {
        color: $brand-color-lighter;
      }
    }
  }

  &.delay-show {
    animation: loading-in 0.2s;
    animation-delay: 0.2s;
    animation-fill-mode: backwards;
  }

  &.pass-through {
    pointer-events: none;
  }

  &.loading-sm .load-icon-wrap {
    font-size: 24px;
  }

  &.loading-lg .load-icon-wrap {
    font-size: 64px;
  }
}

.load {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  position: fixed;
  background: #FFF;
  z-index: 1000;

  & + div {
    height: 100vh;
    overflow: hidden;
  }

  &.loaded {
    animation: ease loaded 0.5s;
  }
}

.load-icon-wrap {
  margin: auto;
  font-size: 48px;
}

.load-icon {
  color: $brand-color;
  margin: auto;
}

@keyframes loaded {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
