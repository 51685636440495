@use 'sass:math';

@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

$custom-border-radius: 2px;
$chip-line-height: 14;
$chip-font-size: 13;
$chip-sm-font-size: 11;

.chip {
  align-items: stretch;
  border-radius: $custom-border-radius;
  display: inline-flex;
  justify-content: center;
  margin-right: 6px;
  margin-bottom: 4px;

  &.chip-sm {
    margin: 0 2px;

    .chip-name {
      border-radius: 2.5px;
      font-weight: 700;
      font-size: #{$chip-sm-font-size}px;
      line-height: math.div($chip-line-height, $chip-sm-font-size);
      padding: 1px 4px;
    }
  }

  .form-control &:first-child {
    margin-left: -6px;
  }

  .form-control &:last-child {
    margin-right: -6px;
  }

  .chip-name {
    font-size: #{$chip-font-size}px;
    letter-spacing: 0;
    line-height: math.div($chip-line-height, $chip-font-size);
    padding: 3px 6px;
    word-break: break-word;
  }

  .chip-btn-wrapper {
    border-radius: $border-radius 0 0 $custom-border-radius;
    background-color: rgba($text-color, 0.2);
    display: flex;
    align-items: center;

    .btn.btn-icon {
      color: $white;
      font-size: 14px;
      height: 100%;
      min-width: 0;
      padding: 0;
      width: 20px;

      &:hover,
      &:active {
        background-color: rgba($text-color, 0.3);
        color: $white;
      }
    }
  }

  .table &,
  .rt-table & {
    max-width: 100%;
    text-align: left;

    .chip-name {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.chip-group {
  margin: -2px;
  max-width: calc(100% + 4px);

  .chip {
    margin: 2px;
  }
}
