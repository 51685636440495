// Fonts
//
@import 'src/scss/1-settings/typography';

// px value on the left, output in rem
$h1-font-size: calc(30 / #{$rem-value} * 1rem) !default;
$h2-font-size: calc(26 / #{$rem-value} * 1rem) !default;
$h3-font-size: calc(20 / #{$rem-value} * 1rem) !default;
$h4-font-size: calc(16 / #{$rem-value} * 1rem) !default;
$h5-font-size: calc(13 / #{$rem-value} * 1rem) !default;
$h6-font-size: calc(11 / #{$rem-value} * 1rem) !default;

$headings-margin-bottom: ($spacer * 0.5) !default;
$headings-font-family: inherit !default;
$headings-font-weight: $font-weight-normal !default;
$headings-line-height: 1.2 !default;
$headings-color: inherit !default;
$headings-letter-spacing: 0.01em;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight: $font-weight-light !default;
$display2-weight: $font-weight-light !default;
$display3-weight: $font-weight-light !default;
$display4-weight: $font-weight-light !default;
$display-line-height: $headings-line-height !default;

$lead-font-size: ($font-size-base * 1.25) !default;
$lead-font-weight: $font-weight-light !default;

$small-font-size: $font-size-sm !default;

$text-muted: $text-color-muted !default;

$blockquote-small-color: $text-color-muted !default;
$blockquote-font-size: ($font-size-base * 1.25) !default;

$hr-border-color: $text-color-muted !default;
$hr-border-width: 1px !default;

$mark-padding: 0.2em !default;

$dt-font-weight: $font-weight-bold !default;

$kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25) !default;
$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: 0.5rem !default;

$mark-bg: #FCF8E3 !default;

$hr-margin-y: $spacer !default;
