@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

dl {
  margin: 12px 0 16px;

  dt {
    padding-right: 0.5em;
  }

  > div {
    padding-bottom: 0;
  }

  dt,
  dd {
    display: inline;
    font-weight: $font-weight-normal;
    margin: 0;
    padding: 0;

    .btn.btn-icon {
      min-width: auto;
    }

    .tooltip-container {
      display: flex;
    }
  }

  dd {
    color: $text-color-medium;
  }

  &.definition-table {
    display: flex;
    flex-direction: column;
    white-space: nowrap;

    > div {
      display: flex;
      justify-content: space-between;
    }

    dt,
    dd {
      display: inline-block;
    }

    dt {
      flex-basis: 40%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    dd {
      flex-basis: 60%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.align-left {
      dt {
        flex-basis: auto;
      }

      > div {
        justify-content: flex-start;
        gap: 8px;
      }
    }
  }
}
