$rootPath: '../';

.account-page {
  background-color: $cool-gray;

  .container {
    max-width: 1860px;
  }
}

.account {
  min-height: 100vh;
  display: flex;
  overflow: visible;

  &.account-photo {
    background-size: cover;

    .account__card {
      background-color: rgba(0, 10, 16, 0.8);
      color: #FFF;
    }

    .form__form-group-icon,
    .form__form-group-button {
      background: transparent;
    }

    .form__form-group-icon,
    input:not(:last-child) {
      border-right: none;
    }

    .form__form-group-button,
    input {
      border-left: none;
    }

    .form__form-group-button.active {
      border-color: #EFF1F5;

      svg {
        fill: $green;
      }
    }

    input {
      color: #FFF;

      &:focus {
        border-color: #EFF1F5;
      }
    }

    p {
      color: #FFF;
    }

    .account__title {
      color: #FFF;
    }
  }

  .account__wrapper {
    margin: 20px auto;
    position: relative;
    z-index: 1;
  }

  .account__head,
  .account-form {
    @include gutter-sizing([ 'padding-left', 'padding-right' ], 2);
    @include gutter-sizing([ 'padding-bottom', 'padding-top' ]);
  }

  .account__card {
    background-color: #F6F6F6;
    box-shadow: 0 80px 200px rgba(0, 0, 0, 0.1), 0 4px 32px rgba(0, 0, 0, 0.04);
    margin: auto;
    max-width: 520px;
    width: 100%;
  }

  .account__head {
    background-color: $background-gray-dark-1;

    .logo {
      display: block;
      margin: 0 auto;
      max-width: 290px;
      width: 100%;
    }
  }

  .account__logo-accent {
    color: $blue;
  }

  .account__have-account {
    text-align: center;
    margin-top: 20px;

    a {
      color: $blue;
      transition: all 0.3s;

      &:hover {
        color: $brand-color-darker;
        text-decoration: none;
      }
    }
  }

  .artwork-container {
    position: relative;
    z-index: 0;

    .artwork {
      margin: 7% auto 0;
      max-height: 90vh;
    }

    &.nba,
    &.nbademo,
    &.wizardingworld {
      img {
        margin: 0 auto;
      }
    }
  }
}

.forgot-password-flow {
  min-height: 100vh;

  .form-container {
    max-width: 768px;
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .text-danger.btn-link {
    text-decoration: underline;
    display: block;
  }

  .card-body {
    padding: 0;
  }

  .card.ui-message {
    margin-bottom: $spacer;

    @media screen and (max-width: $bp-sm) {
      h2 {
        font-size: 24px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  .account-form {
    max-width: 480px;
    width: 100%;
  }

  .artwork {
    max-width: 70vh;
    width: 100%;

    @media screen and (max-height: 600px) {
      display: none;
    }
  }
}
