$MODAL_Z_INDEX: 1050;

.modal-dialog {
  padding: 2rem;
}

// This prevents shifting content when opening Modal on a scrollable page.
// Bootstrap tries to compensate scrollbar & causes this bug.
body.modal-open {
  padding-right: 0 !important;
}
