@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.legend-rows {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 16px;

  li {
    list-style-type: none;
  }

  .legend-single-row {
    display: flex;
    align-items: center;
    flex-direction: row;

    .legend-color-indicator {
      border-radius: 50%;
      height: 12px;
      width: 12px;
      margin-right: 6px;
      flex: 1 0 auto;
    }

    .legend-label {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 4px;

      .text-muted.value-total {
        font-size: 11px;
        color: $gray-400 !important;
      }
    }

    img {
      margin-right: 6px;
      width: 32px;
      height: 40px;
      object-fit: cover;
      flex: 1 0 auto;
    }
  }
}
