@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.global-topbar {
  align-items: center;
  background-color: rgba($background-gray-dark-1, 0.9);
  color: $white;
  display: flex;
  height: $topbar-height;
  justify-content: space-between;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $zindex-fixed;

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    background-color: rgba($background-gray-dark-1, 0.8);
    backdrop-filter: blur(30px);
    // stylelint-disable-next-line property-no-vendor-prefix
    -webkit-backdrop-filter: blur(30px);
  }

  .topbar-logo {
    align-self: flex-end;
    display: block;
    height: 38px;
    margin: 10px auto 12px 20px;

    img {
      height: 100%;
    }
  }

  .topbar-hamburger {
    align-items: center;
    display: flex;
    justify-content: center;
    width: $topbar-height - 16px;
    height: $topbar-height - 16px;
    margin: 8px;
    border-radius: $border-radius;
    overflow: hidden;

    @media screen and (min-width: $bp-xl) {
      display: none;
    }

    @media (hover: hover) {
      &:hover {
        background-color: $background-gray-dark-2;
      }}

    &:focus {
      @include focus-shadow($white);
      outline: 0;
    }

    &.menu-open .hamburger-bar {
      &:before {
        transform: translateY(0%) rotate(45deg) scaleX(0.5);
      }

      &:after {
        transform: translateY(0%) rotate(-45deg) scaleX(0.5);
      }
    }

    .hamburger-bar {
      border-radius: 1px;
      background: $white;
      height: 2px;
      position: relative;
      text-indent: $topbar-height;
      transition: background-color 0.2s linear;
      white-space: nowrap;
      width: 24px;

      &:before,
      &:after {
        content: '';
        display: block;
        background: $white;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        transform-origin: left center;
        transition: transform 0.3s ease-in-out;
        width: 100%;
      }

      &:before {
        transform: translateY(-300%);
      }

      &:after {
        transform: translateY(300%);
      }
    }
  }

  .topbar-left,
  .topbar-right {
    display: flex;
    position: relative;
  }

  .topbar-right {
    padding-right: ($grid-gutter-width - 12px);

    @media screen and (min-width: $bp-md) {
      padding-right: ($grid-gutter-width-md - 12px);
    }

    @media screen and (min-width: $bp-xl) {
      padding-right: ($grid-gutter-width-xl - 12px);
    }
  }

  .topbar-dropdown-nav {
    height: $topbar-height - 16px;
    margin-right: 8px;
    width: $topbar-height - 16px;
  }

  .topbar-dropdown-trigger {
    background-color: rgba(0, 0, 0, 0);
    border-radius: $border-radius;
    border: none;
    color: rgba($white, 0.8);
    font-size: 20px;
    height: $topbar-height - 16px;
    line-height: 1;
    padding: 4px;
    transition: background-color 0.3s, color 0.3s;
    width: $topbar-height - 16px;
  }

  .topbar-dropdown-trigger:hover,
  .show .topbar-dropdown-trigger,
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: $background-gray-dark-3;
    color: $white;
  }

  .dropdown-menu {
    margin-top: -4px;

    .highlight-icon {
      color: $brand-color-lighter;
      transition: color 0.1s;
    }

    .dropdown-item:hover .highlight-icon, {
      color: $white;
    }
  }

  @media screen and (min-width: $bp-sm) {
    .topbar-button {
      &.topbar-button-desktop {
        display: block;
      }

      &.topbar-button-mobile {
        display: none;
      }
    }
  }
}
