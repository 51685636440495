.btn-toolbar {
  margin-top: $spacer;

  & > .btn {
    margin-right: $spacer;
    margin-bottom: $spacer;

    &:last-child {
      margin-right: 0;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  &.btn-toolbar--center {
    & > * {
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.btn-group {
  @include themify($themes) {
    background-color: themed('cardColorHover');
  }

  .btn {
    margin-right: 0;
  }

  &.justified {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .btn {
      width: 100%;
    }
  }

  &.icons {
    .btn {
      padding: 7px 8px;
      line-height: 14px;
    }
  }

  &.open .dropdown-toggle {
    box-shadow: none;
  }
}

.select-engagement-unit-button {
  background-color: $white;
  border-radius: $border-radius-lg;
  box-shadow: $box-shadow-sm;
  color: $black;
  display: block;
  margin-top: $spacer;
  padding: 12px 8px;
  text-align: center;
  transition: box-shadow 0.2s linear;
  width: 100%;

  @media screen and (min-width: $bp-sm) {
    padding: 20px 30px;
  }

  &:hover {
    box-shadow: $box-shadow;
    color: $black;
    text-decoration: none;
  }

  .icon {
    color: $brand-color;
    font-size: 64px;
    margin-bottom: ($spacer * 0.5);
  }

  h3 {
    font-size: 24px;
    font-weight: $font-weight-bold;
  }

  p {
    color: $black-secondary;

    @media screen and (min-width: $bp-sm) {
      max-height: 3em;
      overflow: hidden;
    }

    @media screen and (max-width: $bp-sm) {
      font-size: 12px;
    }
  }
}

.modal .modal-content > .btn.close-btn {
  z-index: 11;

  &.disabled {
    opacity: 1;
  }
}

.btn-group > .tooltip-container:not(:last-child):has(+ .tooltip-container) .btn:not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .tooltip-container .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .tooltip-container:not(:first-child) .btn,
.btn-group > .btn-group:not(:first-child) > .tooltip-container .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.tooltip-container > .btn {
  height: 100%;
}
