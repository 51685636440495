.badge {
  display: inline; // Can be useful to prevent wrapping before badge

  h1 &,
  h2 &,
  h3 &,
  h4 & {
    font-size: 0.466667em;
    margin-left: 0.25em;
    vertical-align: middle;
  }
}
