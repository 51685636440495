.form-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-message {
  left: 15px;
}

.name-field-label {
  word-spacing: -1em;
}
