html {
  overflow: auto;
  overflow-x: hidden;
}

body {
  font-size: 14px;
  overflow: hidden;
  background-color: rgb(23, 26, 37);
}

// Added to body class when dragging an element
.dragging {
  cursor: grabbing;
}

.hr-separator {
  font-size: 14px;
  letter-spacing: 0.2em;
  line-height: 1;
  padding: 7px 0;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  color: $text-color-medium;

  &:after {
    border-bottom: 1px solid $hr-border-color;
    content: '';
    display: block;
    height: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    width: 100%;
  }

  .label {
    padding: 0 0.75em;
    position: relative;
    z-index: 2;
  }
}

.thumbnail-container {
  position: relative;

  &.blank-slate {
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    opacity: 0.5;
    padding: 4px;
    text-align: center;
  }

  .duration {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    bottom: 2px;
    color: white;
    font-size: 11px;
    left: 2px;
    line-height: 1;
    padding: 2px 6px;
    position: absolute;
  }
}

.modal-image {
  margin: auto;
  max-width: 600px;
}

button.modal-close {
  background: $white;
  border-radius: 50px;
  height: 32px;
  right: 10px;
  position: absolute;
  top: 10px;
  width: 32px;

  span {
    color: $black;
    position: relative;
    top: -2px;
  }
}

.modal-content {
  border-width: 0; // Should move this to the SDK as a variable to extend bootstrap
}

.bg-secondary,
.bg-secondary.gradient { // we never want a gradient for this
  background: $border-color-light !important; // important to overwrite SDK
}

// Quick fix for card titles
.panel .card-header {
  flex-wrap: wrap;
}

.card-title {
  white-space: nowrap;
}

// Quick fix for popovers, will likely move to the SDK later
.bs-popover-auto {
  max-width: 340px;
  border-color: $border-color;
}

// Quick fix for OG image preview
.custom-preview-container {
  border-radius: 2px;
  left: 1px;
  overflow: hidden;
  position: absolute;
  top: 1px;
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  z-index: 0;
}

.custom-preview-contents {
  transform-origin: top left;
}

.og-image-message {
  opacity: 0;
  align-items: center;
  backdrop-filter: blur(20px);
  background-color: rgba($white, 0.7);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 2px);
  justify-content: center;
  left: 1px;
  position: absolute;
  top: 1px;
  transition: 0.2s opacity ease;
  width: calc(100% - 2px);
  z-index: 1;

  @supports (backdrop-filter: blur(20px)) {
    background-color: rgba($white, 0.3);
  }

  &:hover,
  :focus & {
    opacity: 1;
  }
}
