@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.graph-container {
  min-height: 400px;
}

.visx-tooltip.visx-tooltip-glyph {
  svg {
    overflow: visible !important;
  }
}

.visx-group {
  &.visx-rows {
    line.visx-line {
      stroke: $gray-300;
      stroke-dasharray: 4, 4;
    }
  }

  &.visx-axis {
    .visx-group.visx-axis-tick {
      line.visx-line {
        stroke: $gray-300;
      }

      text {
        fill: $black-tertiary;
        font-family: inherit;
        font-weight: 500;
      }
    }

    .visx-axis-line {
      stroke: $gray-300;
    }
  }
}
