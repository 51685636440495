$slide-transition-distance: 20px !default;

// Simple fade
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity $transition-duration linear;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity $transition-duration linear;
}

// Fade in and up
.fade-up-enter {
  opacity: 0;
  transform: translateY($slide-transition-distance);
}

.fade-up-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity $transition-duration, transform $transition-duration ease-in-out;
}

.fade-up-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-up-exit-active {
  opacity: 0;
  transform: translateY($slide-transition-distance);
  transition: opacity $transition-duration, transform $transition-duration ease-in-out;
}

// Fade in and down
.fade-down-enter {
  opacity: 0;
  transform: translateY(-$slide-transition-distance);
}

.fade-down-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity $transition-duration, transform $transition-duration ease-in-out;
}

.fade-down-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-down-exit-active {
  opacity: 0;
  transform: translateY(-$slide-transition-distance);
  transition: opacity $transition-duration, transform $transition-duration ease-in-out;
}

// Fade in and left
.fade-left-enter {
  opacity: 0;
  transform: translateX($slide-transition-distance);
}

.fade-left-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity $transition-duration, transform $transition-duration ease-in-out;
}

.fade-left-exit {
  opacity: 1;
  transform: translateX(0);
}

.fade-left-exit-active {
  opacity: 0;
  transform: translateX($slide-transition-distance);
  transition: opacity $transition-duration, transform $transition-duration ease-in-out;
}

// Fade in and right
.fade-right-enter {
  opacity: 0;
  transform: translateX(-$slide-transition-distance);
}

.fade-right-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity $transition-duration, transform $transition-duration ease-in-out;
}

.fade-right-exit {
  opacity: 1;
  transform: translateX(0);
}

.fade-right-exit-active {
  opacity: 0;
  transform: translateX(-$slide-transition-distance);
  transition: opacity $transition-duration, transform $transition-duration ease-in-out;
}
