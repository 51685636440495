@import 'src/scss/1-settings/all';

.StatusSelector__container {
  border-bottom: 1px solid $border-color-light;
  padding-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  > div {
    flex-grow: 1;
  }
}
