.select-field-clear-indicator {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  .select-field-link-button {
    width: 20px;
    height: 20px;
    min-width: 20px;
  }
}
