@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.global-app-wrapper {
  min-height: 100vh;
  font-size: 14px;
  line-height: calc(20 / 14);
  padding: $topbar-height 0 0;
  transition: padding-left 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media screen and (min-width: $bp-md) {
    padding: $topbar-height $grid-gutter-width-md $grid-gutter-width-md $sidebar-collapsed-width;
  }

  @media screen and (min-width: $bp-xl) {
    padding: $topbar-height $grid-gutter-width-xl $grid-gutter-width-xl $sidebar-expanded-width;
  }
}
