@use 'sass:math';
@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

$triangle-size: 8px;

.form-row {
  font-size: $input-font-size;
  padding-bottom: 9px;
  padding-top: 5px;

  .optional-flag {
    font-weight: 500;
  }

  @media screen and (min-width: $bp-md) {
    font-size: $font-size-base-md-up;
    padding-bottom: 4px;
    padding-top: 4px;
  }
}

// Validation
.form__form-group-error,
.form__form-group-error.invalid-feedback {
  left: 0;
  max-width: 100%;
  position: absolute;
  width: auto;
  z-index: 1;
  font-size: 11px;
  line-height: math.div(14, 11);

  &:after {
    z-index: 1;
  }
}

.form__form-group-input-wrap--error-below {
  position: relative;

  .form-control,
  [class^='css-'] {
    transition: margin-bottom 0.3s ease-in-out;
    will-change: margin-bottom;
  }

  .form__form-group-error {
    top: 100%;
    margin: 4px 0 0;

    &:before {
      border: $triangle-size solid transparent;
      border-bottom: $triangle-size solid $red;
      top: -1 - ($triangle-size * 2);
    }

    &:after {
      border: $triangle-size solid transparent;
      border-bottom: $triangle-size solid mix($red, white, 15%);
      top: 0 - ($triangle-size * 2);
    }
  }
}

.form__form-group-input-wrap--error-above {
  position: relative;

  .form-control,
  [class^='css-'] {
    transition: margin-bottom 0.3s ease-in-out;
    will-change: margin-bottom;
  }

  .form__form-group-error {
    bottom: 100%;
    margin: 0 0 4px;

    &:before {
      border: $triangle-size solid transparent;
      border-top: $triangle-size solid $red;
      bottom: -1 - ($triangle-size * 2);
    }

    &:after {
      border: $triangle-size solid transparent;
      border-top: $triangle-size solid mix($red, white, 15%);
      bottom: 0 - ($triangle-size * 2);
    }
  }

  .is-invalid {
    margin-top: 26px;

    @media screen and (min-width: $bp-md) {
      margin-top: 17px;
    }
  }
}
