// Progress bars

$progress-height: 1.5em !default;
$progress-font-size: 1em !default;
$progress-bg: rgba(125, 125, 125, 0.15) !default;
$progress-border-radius: $border-radius !default;
$progress-box-shadow: inset 0 0.1rem 0.1rem rgba($black, 0.1) !default;
$progress-bar-color: $white !default;
$progress-bar-bg: theme-color('primary') !default;
$progress-bar-animation-timing: 1s linear infinite !default;
$progress-bar-transition: width 0.4s ease !default;
