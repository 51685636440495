@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.global-sidebar {
  background-color: $background-gray-dark-1;
  left: 0 - $sidebar-expanded-width;
  height: calc(100vh - #{$topbar-height});
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 20px;
  position: fixed;
  top: $topbar-height;
  transform: translateX(0%);
  transition: transform 0.2s ease-in;
  width: $sidebar-expanded-width;
  z-index: $zindex-fixed;

  @supports (height: -webkit-fill-available) {
    // stylelint-disable-next-line value-no-vendor-prefix
    height: -webkit-fill-available;
    padding-bottom: 80px;
  }

  .loading-ui {
    background-color: $background-gray-dark-1;
    background-image: url('./img/sidebar-loading.svg');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 165px;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  @media screen and (max-width: ($bp-md - 1px)) {
    .sidebar-open & {
      transform: translateX(100%);
      transition: transform 0.2s ease-out;
    }
  }

  @media screen and (min-width: $bp-md) and (max-width: ($bp-xl - 1px)) {
    width: $sidebar-collapsed-width;
    will-change: width;

    .loading-ui {
      background-image: url('./img/sidebar-loading-tablet.svg');
      background-size: 60px;
    }

    .nav-point-title {
      transform: translateX(32px);
    }

    .sidebar-open & {
      width: $sidebar-expanded-width;

      .nav-point-title {
        transform: translateX(0);
      }
    }
  }

  @media screen and (min-width: $bp-md) {
    left: 0;
    transition: width 0.3s ease-in-out;
  }

  @media screen and (min-width: $bp-xl) {
    width: $sidebar-expanded-width;
  }

  .closed.nav-point.parent-of-current {
    background-color: $background-gray-dark-2;
    color: $white;

    &:before {
      opacity: 1;
    }
  }

  .nav-point {
    align-items: center;
    color: $white;
    display: flex;
    height: 56px;
    overflow: hidden;
    padding: 10px 8px 12px 50px;
    position: relative;
    text-decoration: none;
    text-overflow: ellipsis;
    transition: background-color 0.2s linear;
    white-space: nowrap;
    width: 100%;

    @media screen and (min-width: $bp-md) {
      width: $sidebar-expanded-width !important;
    }

    &:before {
      background-color: $brand-color;
      bottom: 0;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity 0.2s linear;
      width: 4px;
    }

    .icon {
      color: $brand-color;
      font-size: 16px;
      left: 20px;
      position: absolute;
      top: 48%;
      transform: translateY(-50%);
      text-align: center;
      width: 16px;
    }

    .nav-point-title {
      flex: 1;
      font-size: $font-size-base-md-up;
      line-height: calc(20 / 14);
      overflow: hidden;
      text-overflow: ellipsis;
      transition: transform 0.3s ease-in-out;
      white-space: nowrap;
    }

    .expand-icon {
      color: $brand-color;
      font-size: 12px;
      margin: 0 12px;
      transition: transform 0.2s ease-in-out;
      transform: rotate(0);
    }

    .menuCounter {
      background-color: $red-500;
      color: white;
      border-radius: 50px;
      height: 20px;
      min-width: 20px;
      text-align: center;
      margin-left: 10px;
      padding: 0 3px;
    }

    @media (hover: hover) {
      &:hover,
      &.current {
        background-color: $background-gray-dark-2;
        color: $white;

        &:before {
          opacity: 1;
        }
      }
    }

    &.current {
      background-color: $background-gray-dark-2;
      color: $white;

      &:before {
        opacity: 1;
      }
    }
  }

  .sub-menu {
    .nav-point.open .expand-icon {
      transform: rotate(-180deg);
    }

    .collapse-content {
      padding-top: 0;
    }

    &.current {
      background-color: #262C43;
      color: #FFF;
    }
  }

  .sub-nav-point {
    height: 32px;
  }

  hr {
    background: linear-gradient(90deg, $border-color-dark 0%, rgba(72, 72, 72, 0) 100%);
    border-top: 0;
    height: 1px;
    margin: rem(20) 0;

    &:first-child {
      margin-top: 0;
    }
  }
}

.sidebar-overlay {
  background: rgba($modal-backdrop-bg, 0.2);
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  touch-action: none;
  width: 100%;
  z-index: ($zindex-fixed - 1);

  &:focus {
    outline: 0;
  }
}
