// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: rem(260);
$dropdown-padding-y: rem(20);
$dropdown-spacer: 0 - $border-width;
$dropdown-bg: $background-gray-dark-3;
$dropdown-border-color: transparent;
$dropdown-border-radius: $border-radius;
$dropdown-border-width: 0;
$dropdown-divider-bg: inherit;
$dropdown-box-shadow: -2px 3px 25px rgba(0, 0, 0, 0.15), -1px 6px 14px rgba(0, 0, 0, 0.08), 0 1px 2px rgba(0, 0, 0, 0.18);

$dropdown-link-color: inherit;
$dropdown-link-hover-color: inherit;
$dropdown-link-hover-bg: $link-color;

$dropdown-link-active-color: inherit;
$dropdown-link-hover-bg: $link-color;

$dropdown-link-disabled-color: $white;

$dropdown-item-padding-y: rem(4);
$dropdown-item-padding-x: rem(10);

$dropdown-header-color: rgba($white, 0.7);
