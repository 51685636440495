@use 'sass:math';

@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

$file-row-height: 64px;

// Animation styles, order is important here
.upload-summary-enter {
  transform: translateY(100%);
}

.upload-summary-enter-active {
  transform: translateY(0);
  transition: transform 0.35s ease-out;
}

.upload-summary-exit {
  transform: translateY(0);
}

.upload-summary-exit-active {
  transform: translateY(100%);
  transition: transform 0.35s ease-in;
}

// End animation styles

.upload-summary {
  bottom: 0;
  box-shadow: $box-shadow;
  max-width: 500px;
  overflow: hidden;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 11;

  @media screen and (min-width: 500px) {
    border-top-left-radius: $border-radius;
  }

  .upload-summary-header {
    align-items: center;
    background-color: $background-gray-dark-2;
    color: $white;
    display: flex;
    padding: 10px 16px;

    @media screen and (min-width: $bp-xl) {
      padding: 14px 20px;
    }

    .cancel-btn {
      border-radius: 16px;
      font-size: 16px;
      height: 32px;
      left: 24px;
      position: absolute;
      text-align: center;
      top: 48%;
      transform: translate(-50%, -50%);
      width: 32px;

      &:hover {
        background-color: rgba($white, 0.15);
      }
    }

    h4 {
      flex: 1;
      line-height: math.div(20, 16);
    }

    .collapse-icon-progress {
      height: 32px;
      padding: 2px;
      position: absolute;
      right: 10px;
      width: 32px;

      @media screen and (min-width: $bp-xl) {
        height: 40px;
        width: 40px;
      }

      .trigger-icon {
        align-items: center;
        display: flex;
        font-size: 10px;
        height: 24px;
        justify-content: center;
        left: 50%;
        padding-bottom: 1px;
        position: absolute;
        top: 50%;
        width: 24px;

        @media screen and (min-width: $bp-xl) {
          font-size: 16px;
        }

        &.no-rotate {
          transform: translate(-50%, -50%) rotate(0deg);
        }
      }
    }
  }

  &.has-cancel .upload-summary-header {
    padding-left: 44px;
  }

  &.collapse-container .collapse-content {
    background-color: $white;
    padding: 0;
  }

  .file-list-container {
    max-height: calc(100vh - 60px);
    overflow: auto;
    padding: 8px 0;

    @media screen and (min-height: 400px) {
      max-height: 302px;
    }

    @media screen and (min-width: $bp-xl) {
      padding: 10px 0;
    }
  }

  .upload-preview-row {
    align-items: center;
    display: flex;
    padding: 8px 16px;
    position: relative;

    @media screen and (min-width: $bp-xl) {
      padding: 10px 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .file-preview {
      align-items: center;
      background-color: $background-gray-light-3;
      border-radius: $border-radius;
      border: 0;
      display: flex;
      height: $file-row-height;
      justify-content: center;
      margin: 0 16px 0 0;
      min-width: $file-row-height;
      overflow: hidden;
      width: $file-row-height;

      &.image-component.blur-fill .background {
        filter: brightness(1.2) blur(40px);
      }

      .placeholder-icon {
        font-size: 32px;
      }
    }

    .file-info-container {
      align-items: center;
      display: flex;
      flex: 1;
      height: $file-row-height;
      min-width: 1px;
      position: relative;
    }

    .file-info {
      max-width: 100%;
    }

    .file-name {
      margin-bottom: -2px;
    }

    .file-name,
    .file-size {
      max-width: 100%;
      overflow: hidden;
      padding-right: 42px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .status-icon {
      font-size: 24px;
      margin-top: -12px;
      position: absolute;
      right: 12px;
      top: 50%;

      @media screen and (min-width: $bp-xl) {
        right: 20px;
      }
    }
  }

  .uploading-bar {
    bottom: 2px;
    left: 0;
    margin: 0;
    position: absolute;
    right: 0;
    width: 100%;
  }
}
