@use 'sass:math';

.engagement-unit-preview-container {
  font-feature-settings: normal;
  height: 0;
  letter-spacing: 0.03em;
  position: absolute;
  padding-bottom: (math.div(667, 375) * 100%);
  width: 100%;

  &.has-sf {
    letter-spacing: 0.01em;
  }
}
