@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.pagination {
  align-items: center;
  column-gap: 15px;
  display: flex;
  justify-content: right;
  margin-top: 20px;
  margin-right: 5px;

  .choose-page {
    align-items: center;
    display: flex;
  }

  .page-jump {
    margin-left: ($spacer * 0.5);
    margin-right: ($spacer * 0.5);
    width: 64px;
  }
}
