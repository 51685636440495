@import 'src/scss/1-settings/all';
@import 'src/scss/2-tools/all';

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  position: relative;
  white-space: nowrap;
  font-weight: $font-weight-medium;

  span {
    width: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;

    &.align-left {
      justify-content: flex-start;
    }

    &.align-right {
      justify-content: flex-end;
    }

    &.align-center {
      justify-content: center;
    }

    &.icon-gap-sm {
      gap: 4px;
    }

    &.icon-gap-md {
      gap: 10px;
    }
  }

  svg {
    fill: currentColor;
    pointer-events: none;
  }

  &.font-weight-bold {
    font-weight: 500 !important;
  }

  &.btn-icon {
    align-items: center;
    display: inline-flex;
    height: 40px;
    justify-content: center;
    line-height: 1;
    min-width: 40px;
    padding: 0;
    width: 40px;

    &.btn-sm {
      height: 30px;
      font-size: 13px;
      min-width: 30px;
      width: 30px;
    }

    &.btn-md {
      height: 36px;
      width: 40px;
    }

    &.btn-lg {
      font-size: rem(22);
      height: 60px;
      min-width: 60px;
      width: 60px;
    }

    &.btn-link {
      height: auto;
      width: auto;
    }
  }

  &.btn-icon-mobile {
    .desktop-text {
      @media screen and (max-width: $bp-md) {
        @include sr-only;
      }
    }
  }

  &.btn-light {
    background-color: $white;

    &:hover {
      border-color: $white;
    }
  }

  &.btn-outline-light {
    color: $white;
    border-color: $white;

    &:hover:not(.disabled) {
      background-color: $white;
    }
  }

  &.btn-light:focus {
    box-shadow: 0 0 0 $input-btn-focus-width rgba($text-color, 0.4);
  }

  &.black-tertiary {
    background-color: $black-tertiary;
    color: $white;
  }

  &.gray {
    background-color: $gray-400;
    color: $white;
  }

  &.btn-outline-black-tertiary {
    @include button-outline-variant($black-tertiary, $white, $black-tertiary);

    /** Since $black-tertiary is an rgba colour, when the border and the background
    both have that colour it looks as if the border is black. So we need to override
    the hover styles to hide the border. */
    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):active {
      border-color: transparent;
    }
  }

  &.light-blue {
    color: $blue-300;
    border-color: $blue-300;
    background-color: transparent;

    &:hover {
      background-color: $blue-300;
      color: white;
    }
  }
}

// For light or dark buttons with colored text
.btn.text-primary:focus {
  @include focus-shadow($primary);
}

.btn.text-secondary:focus {
  @include focus-shadow($secondary);
}

.btn.text-success:focus {
  @include focus-shadow($success);
}

.btn.text-info:focus {
  @include focus-shadow($info);
}

.btn.text-warning:focus {
  @include focus-shadow($warning);
}

.btn.text-danger:focus {
  @include focus-shadow($danger);
}

.btn.light-blue:focus {
  @include focus-shadow($blue-300);
}

.btn-outline-info.disabled:hover {
  color: $info;
}

a.btn {
  appearance: none;
  // Looks like this isn't being added by PostCSS as it should
  // stylelint-disable-next-line property-no-vendor-prefix
  -webkit-appearance: none;
}

.loading {
  cursor: default;
}

.loading-indicator {
  background-color: inherit;
  bottom: 0;
  color: inherit;
  font-size: 1.75em;
  left: 0;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.loading-indicator-outline {
  background-color: currentColor;
}

.loading-indicator-link {
  background-color: transparent;
  color: transparent;
}

.loading-indicator-link .ellipsis-1,
.loading-indicator-link .ellipsis-2,
.loading-indicator-link .ellipsis-3,
.loading-indicator-link .ellipsis-4 {
  background-color: $black;
}

.ellipsis-container {
  height: 11px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 65px;

  &.isIconOnly {
    transform: translate(-50%, -50%) scale(0.5);
  }
}

.ellipsis-1,
.ellipsis-2,
.ellipsis-3,
.ellipsis-4 {
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  background: $white;
  border-radius: 50%;
  height: 11px;
  position: absolute;
  top: 0;
  width: 11px;
}

.ellipsis-1 {
  left: 6px;
  animation: ellipsis1 0.6s infinite;
}

.ellipsis-2 {
  left: 6px;
  animation: ellipsis2 0.6s infinite;
}

.ellipsis-3 {
  left: 26px;
  animation: ellipsis2 0.6s infinite;
}

.ellipsis-4 {
  left: 45px;
  animation: ellipsis3 0.6s infinite;
}

@keyframes ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(19px, 0);
  }
}

@keyframes ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}
