// Spacing
//
// Control the styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variabl
$spacers: map-merge((
  0: 0,
  1: (
    $spacer * 0.25
  ),
  2: (
    $spacer * 0.5
  ),
  3: $spacer,
  4: (
    $spacer * 1.5
  ),
  5: (
    $spacer * 3
  )
), $spacers) !default;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variabl
$sizes: map-merge((
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
  auto: auto
), $sizes) !default;

$border-width: 1px !default;
$border-radius: 0.25rem !default;
$border-radius-lg: 0.4rem !default;
$border-radius-sm: 0.2rem !default;
$border-radius-xl: 16px !default;

$caret-width: 0.3em !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$line-height-base: 1.5 !default;
$font-size-lg: rem(20) !default;
$font-size-sm: rem(12) !default;
$font-size-xs: rem(10) !default;

// Paragraphs
//
// Style p element.

$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;
$paragraph-margin-bottom: ($line-height-lg * 1em) !default;

$input-btn-padding-y: rem(6) !default;
$input-btn-padding-x: rem(12) !default;
$input-btn-font-family: null !default;
$input-btn-font-size: rem(14) !default;
$input-btn-line-height: calc(22 / 16) !default;
// additional var for responsive font sizing
$font-size-base-md-up: $input-btn-font-size !default;

$input-btn-focus-width: 0.2rem !default;
$input-btn-focus-color: rgba($component-active-bg, 0.25) !default;
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm: 0.2rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
$input-btn-font-size-sm: $font-size-sm !default;
$input-btn-line-height-sm: $line-height-sm !default;

$input-btn-padding-y-lg: 0.5rem !default;
$input-btn-padding-x-lg: 1.5rem !default;
$input-btn-font-size-lg: $font-size-base !default;
$input-btn-line-height-lg: $line-height-base !default;

$input-btn-border-width: $border-width !default;
