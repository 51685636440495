html {
  background-color: $background-gray-dark-1;
  color: $white;
  font-size: ($rem-value * 1px);
  line-height: 1.6;
}

body {
  background-color: $background-gray-dark-1;
  overflow-x: hidden;
  overflow-y: scroll;
}

main {
  padding: 0;
}

ul,
ol {
  padding-left: 1em;
  margin-bottom: 0;
}

a {
  transition: color 0.3s;
}

img,
video {
  display: block;
  height: auto;
  max-width: 100%;
}

// Shadow DOM Styles
::selection {
  background: rgba(tint($info, 50), 0.6);
}

::-webkit-scrollbar {
  background-color: $background-gray-dark-2;
  box-shadow: -1px 1px 7px rgba(black, 0.15) inset;
  height: 8px;
  padding: 1px;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(white, 0.2);
}

// Light scrollbar in cards
.content-container {
  ::-webkit-scrollbar {
    background-color: $background-gray-light-1;
    box-shadow: -1px 1px 7px rgba(black, 0.15) inset;
    height: 8px;
    padding: 1px;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(black, 0.2);
  }
}
